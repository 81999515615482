import _ from 'lodash';

const settingsValuesDefault = {
  chatBot: false,
};
const settingsSelector = (settings) => {
  const settingsValues: Record<string, never> = {};
  settings.forEach((row) => {
    if (row?.key_item === 'chatBot') {
      _.set(settingsValues, row?.key_item, row?.val !== '0');
    } else if (row?.key_item === 'campaignTermsAndConditions') {
      _.set(settingsValues, row?.key_item, row?.val !== '0');
    } else {
      _.set(settingsValues, row?.key_item, row?.val);
    }
  });

  return { ...settingsValuesDefault, ...settingsValues };
};

export { settingsSelector };
