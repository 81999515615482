import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import { getParticipantPlatformAPI } from '../api/ParticipantPlatformApi';
import { patientProfileSelector } from '../selector/PatientProfileSelector';
import {
  getParticipantPlatformError,
  getParticipantPlatformSuccess,
} from '../actions/ParticipantPlatform';
import { PARTICIPANT_PLATFORM_REQUEST } from '../types/ParticipantPlatformTypes';
import { participantPlatformSelector } from '../selector/ParticipantPlatformSelector';

function* getParticipantPlatform(actionType) {
  const participantPlatformChannel = yield actionChannel(actionType);
  while (true) {
    yield take(participantPlatformChannel);

    try {
      const response = yield call(getParticipantPlatformAPI);
      yield put(getParticipantPlatformSuccess(participantPlatformSelector(response.data)));
    } catch ({ message }) {
      yield put(getParticipantPlatformError({ message }));
    }
  }
}

function* participantPlatformSaga() {
  yield fork(getParticipantPlatform, PARTICIPANT_PLATFORM_REQUEST);
}

export default participantPlatformSaga;
