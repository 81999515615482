import { AnyAction } from 'redux';
import {
  GET_ABOUT_US_SUCCESS,
  GET_PRIVACY_POLICY_SUCCESS,
  GET_STRIPE_TERMS_SUCCESS,
  GET_TERMS_SUCCESS,
  GET_GDPR_SUCCESS,
  GET_TWILIO_TERMS_SUCCESS,
  GET_CAMPAIGN_RULES_SUCCESS,
} from 'shared/redux/types/StaticPagesTypes';

const AboutUsReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case GET_ABOUT_US_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const PrivacyPolicyReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case GET_PRIVACY_POLICY_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const TermsReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case GET_TERMS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const GDPRReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case GET_GDPR_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const CampaignRulesReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case GET_CAMPAIGN_RULES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const TwilioTermsReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case GET_TWILIO_TERMS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};
const StripeTermsReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case GET_STRIPE_TERMS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export {
  AboutUsReducer,
  PrivacyPolicyReducer,
  TermsReducer,
  GDPRReducer,
  CampaignRulesReducer,
  TwilioTermsReducer,
  StripeTermsReducer,
};
