import i18next from 'i18next';
import {
  getAge,
  translatedDate,
  translatedFullDate,
  translatedMonthAndYear,
} from 'shared/modules/DateTimeUtils';
import Utils from 'shared/modules/Utils';
import { resolveAvailability } from 'shared/modules/DoctorDataFormatter';
import { snakeToCamel } from 'shared/modules/StringUtils';
import dayjs from 'dayjs';

const resolveLanguages = (languages) => {
  return languages?.map((lang) => {
    return `${lang.translations?.find((row) => row.locale === i18next.language)?.name ?? ''} `;
  });
};

const resolveDoctorDetails = (doctorSpecialization) => {
  const { doctor = {}, specialization = {} } = doctorSpecialization ?? {};
  return {
    id: doctor?.id,
    email: doctor.is_resident ? '' : doctor?.email,
    accountStatus: doctor.account_status,
    firstName: doctor.is_resident ? 'Medic' : doctor.first_name ?? '',
    lastName: doctor.is_resident ? 'Ringdoc' : doctor.last_name ?? '',
    fullNameWithTitle: doctor.is_resident ? `Medic Ringdoc` : doctor.full_name_with_title ?? '',
    numericPersonalCode: doctor.numeric_personal_code,
    gender: Utils.solveGender(doctor?.gender),
    birthDate: doctor.birth_date,
    phonePrefix: doctor.phone_number_country?.calling_code,
    phonePrefixId: doctor.phone_number_country?.id,
    phone: doctor.phone_number,
    picture: doctor.picture_media?.public_url ?? null,
    imgUrl: doctor.picture_media?.public_url ?? null,
    averageRating: doctor.average_rating,
    countRating: doctor.count_rating,
    availability: resolveAvailability(
      doctor.is_available_for_call_now,
      doctor.is_busy,
      doctor.is_fake_busy,
    ),
    isAvailableForCallNow: doctor.is_available_for_call_now,
    isBusy: doctor.is_busy,
    isAvailableForEmergency: doctor.is_available_for_emergency,
    isAvailableForFreeCall: doctor.is_available_for_free_talk,
    userLanguages: resolveLanguages(doctor?.user_languages),
    isResident: doctor.is_resident,
    specialization: {
      id: specialization?.id,
      isActive: specialization.is_active,
      isVisible: specialization.is_visible,
      hasEmergency: specialization.has_emergency,
      name: specialization.translations?.find((row) => row.locale === i18next.language)?.name ?? '',
      description:
        specialization.translations?.find((row) => row.locale === i18next.language)?.description ??
        '',
      isValidated: specialization.is_validated,
      isAvailable: specialization.is_available,
      price: specialization.price_as_string,
    },
  };
};

const resolvePatientKinshipDiseases = (diseases) => {
  return diseases?.map((row) => {
    return {
      id: row?.id,
      kinship: row.kinship.translations?.find((r) => r.locale === i18next.language)?.name ?? '',
      diseases: row.diseases,
    };
  });
};

const resolvePatientDetails = (patient) => {
  return {
    id: patient?.id,
    email: patient.email,
    fullNameWithTitle: patient.full_name_with_title,
    firstName: patient.first_name,
    lastName: patient.last_name,
    accountStatus: patient.account_status,
    numericPersonalCode: patient.numeric_personal_code,
    gender: Utils.solveGender(patient?.gender),
    birthDate: getAge(patient.birth_date),
    phonePrefix: patient.phone_number_country?.calling_code,
    phonePrefixId: patient.phone_number_country?.id,
    phone: patient.phone_number,
    picture: patient.picture_media?.public_url ?? null,
    userLanguages: resolveLanguages(patient?.user_languages),
    weight: patient.weight,
    height: patient.height,
    bloodSystem: patient.blood_system,
    isSmoker: patient.is_smoker,
    isAlcoholConsumer: patient.is_alcohol_consumer,
    knownAllergies: patient.known_allergies,
    ongoingTreatments: patient.ongoing_treatments,
    pastMedicalConditions: patient.past_medical_conditions,
    currentMedicalConditions: patient.current_medical_conditions,
    patientKinshipDiseases: resolvePatientKinshipDiseases(patient.patient_kinship_diseases),
  };
};

const resolveUserDetails = (user) => {
  return {
    id: user?.id,
    email: user.email,
    firstName: user.first_name,
    lastName: user.last_name,
    accountStatus: user.account_status,
    gender: Utils.solveGender(user?.gender),
    numericPersonalCode: user.numeric_personal_code,
    birthDate: user.birth_date,
    phonePrefix: user.phone_number_country?.calling_code,
    phonePrefixId: user.phone_number_country?.id,
    phone: user.phone_number,
    picture: user.picture_media?.public_url ?? null,
    userLanguages: resolveLanguages(user?.user_languages),
  };
};

const resolveAppointmentType = (appointmentType) => {
  return {
    id: appointmentType?.id,
    type: appointmentType.type,
    duration: appointmentType.duration,
    pause: appointmentType.pause,
    isAvailable: appointmentType.is_available,
    minPrice: appointmentType.min_price,
    maxPrice: appointmentType.max_price,
  };
};

const solveStatus = (status) => {
  switch (status) {
    case 'created':
      return `${i18next.t('states.created')}`;
    case 'profile_selected':
      return `${i18next.t('states.profile_selected')}`;
    case 'waiting_for_payment':
      return `${i18next.t('states.waiting_for_payment')}`;
    case 'searching_for_doctor':
      return `${i18next.t('states.searching_for_doctor')}`;
    case 'ready_to_start':
      return `${i18next.t('states.ready_to_start')}`;
    case 'rejected_by_doctor':
      return `${i18next.t('states.rejected_by_doctor')}`;
    case 'cancelled_search':
      return `${i18next.t('states.cancelled_search')}`;
    case 'deleted':
      return `${i18next.t('states.deleted')}`;
    case 'cancelled_by_patient':
      return `${i18next.t('states.cancelled_by_patient')}`;
    case 'in_progress':
      return `${i18next.t('states.in_progress')}`;
    case 'cancelled_by_doctor':
      return `${i18next.t('states.cancelled_by_doctor')}`;
    case 'call_ended':
      return `${i18next.t('states.call_ended')}`;
    case 'completed':
      return `${i18next.t('states.completed')}`;
    case 'cancelled':
      return `${i18next.t('states.cancelled')}`;
    case 'canceled':
      return `${i18next.t('states.canceled')}`;
    default:
      return status;
  }
};

const isFinished = (status) => {
  switch (status) {
    case 'created':
    case 'profile_selected':
    case 'waiting_for_payment':
    case 'searching_for_doctor':
    case 'ready_to_start':
    case 'in_progress':
    case 'call_ended':
      return false;
    case 'rejected_by_doctor':
    case 'cancelled_search':
    case 'deleted':
    case 'cancelled_by_patient':
    case 'cancelled_by_doctor':
    case 'completed':
    case 'cancelled':
    case 'canceled':
      return true;
    default:
      return false;
  }
};

const patientAppointmentsSelector = (appointments, withSort = false) => {
  // eslint-disable-next-line no-underscore-dangle
  const appointmentsArray = appointments._embedded?.items ?? [];
  const data: Array<Record<string, unknown>> = [];
  // eslint-disable-next-line array-callback-return
  appointmentsArray.map((row) => {
    data.push({
      id: row?.id,
      user: resolveUserDetails(row?.user ?? {}),
      patient: resolvePatientDetails(row.patient ?? {}),
      appointmentType: resolveAppointmentType(row.appointment_type),
      type: snakeToCamel(row.appointment_type.type),
      doctor: resolveDoctorDetails(row.doctor_specialization),
      date: row.time_start,
      scheduledAt: row.time_start,
      dateToSort: dayjs(row.time_start).valueOf(),
      dateFormatted: translatedMonthAndYear(row.time_start),
      dateFormattedFull: translatedDate(row.time_start),
      time: dayjs(row.time_start).format('HH:mm A'),
      status: solveStatus(row.status),
      serverStatus: row.status,
      specialization:
        row?.specialization?.translations?.find((r) => r.locale === i18next.language)?.name ?? '',
      conclusion: row.appointment_conclusion,
      price: row.price_as_string,
      priceGrossAsString: row.price_gross_as_string,
      discountAsString: row?.discount_as_string,
      duration: row.duration_in_minutes,
      priceAsString: row.price_as_string,
      reviews: row.reviews?.map((review) => {
        return {
          ...review,
          createdAt: review.created_at,
        };
      }),
      isPaid: row.is_paid,
      paymentLimitText: row.payment_time_limit_message,
      paymentLimitDate: row.payment_time_limit,
      sharedFiles: row.appointment_patient_medical_records
        ? row.appointment_patient_medical_records.map(
            (appointment) => appointment.patient_medical_record?.id,
          )
        : [],
      service: row.service,
    });
  });
  const compare = (a, b) => {
    if (a.dateToSort > b.dateToSort) return -1;
    if (a.dateToSort < b.dateToSort) return 1;
    return 0;
  };
  const { pages } = appointments;
  return { noOfPages: pages, data: withSort ? data.sort(compare) : data };
};

const resolveRecords = (records) => {
  const resolveMedias = (medias) => {
    return medias?.map((media) => {
      return {
        ...media,
        id: media?.id,
        externalId: media.media?.id,
        type: media?.media?.gcloud_upload_policy_v4?.fields.key?.split('.')?.pop() ?? '',
      };
    });
  };
  return records.map((record) => {
    return {
      id: record?.patient_medical_record?.id,
      title: record?.patient_medical_record?.title,
      categoryId: record?.patient_medical_record?.medical_record_category?.id,
      categoryName:
        record?.patient_medical_record?.medical_record_category?.translations?.find(
          (r) => r.locale === i18next.language,
        )?.name ?? '',
      type:
        record?.patient_medical_record?.files_portfolio?.media_portfolios[0]?.media?.gcloud_upload_policy_v4?.fields.key
          ?.split('.')
          ?.pop() ?? '',
      files: resolveMedias(record?.patient_medical_record?.files_portfolio?.media_portfolios),
      createdAtFormatted: translatedMonthAndYear(record.created_at),
      createdAtFullFormatted: translatedFullDate(record.created_at),
      createdAt: record.created_at,
      investigationDate: record?.patient_medical_record?.investigation_date,
    };
  });
};

const patientAppointmentDetailsSelector = (appointment) => {
  const hasReviews = Array.isArray(appointment.reviews) && appointment.reviews.length;
  const resolveFiles = (rawFiles) => {
    console.log('rawFiles', rawFiles);
    if (!rawFiles) return [];
    return rawFiles.map((file) => ({
      name: file?.media?.original_file_name,
      id: file.media?.id,
      createdAt: translatedFullDate(file.media?.created_at, true, true),
      label: file.media?.label,
    }));
  };
  const resolveCampaign = (data) => {
    return {
      id: data?.id,
      isActive: data?.is_active,
      dateStart: data?.date_start,
      dateEnd: data?.date_end,
      discount: data?.discount,
      discountType: data?.discount_type,
      name: data?.name,
      description: data?.description,
      priority: data?.priority,
      forAppointmentNo: data?.for_appointment_no,
    };
  };
  const resolveVoucher = (row) => {
    return {
      id: row?.id,
      code: row?.code,
      name: row?.name,
      isActive: row?.is_active,
      dateStart: row?.date_start,
      dateEnd: row?.date_end,
      shortDesc: row?.description,
      discount: row?.discount,
      discountType: row?.discount_type,
      discountAsString: row?.discount_as_string,
      maxUses: row?.max_uses,
      eligible: row?.eligible,
      campaign: row?.campaign ? resolveCampaign(row?.campaign) : undefined,
    };
  };
  return {
    id: appointment?.id,
    user: resolveUserDetails(appointment.user ?? {}),
    patient: resolvePatientDetails(appointment.patient ?? {}),
    doctor: resolveDoctorDetails(appointment.doctor_specialization),
    appointmentType: resolveAppointmentType(appointment.appointment_type),
    date: appointment.time_start,
    scheduledAt: appointment.time_start,
    dateHeader: translatedMonthAndYear(appointment.time_start),
    dateFormatted: translatedFullDate(appointment.time_start, true, true),
    time: dayjs(appointment.time_start).format('HH:mm'),
    status: solveStatus(appointment.status),
    serverStatus: appointment.status,
    isFinished: isFinished(appointment.status),
    isCancelled:
      [
        'cancelled',
        'cancelled_search',
        'cancelled_by_doctor',
        'cancelled_by_patient',
        'rejected_by_doctor',
      ].indexOf(appointment.status) > -1,
    isPaid: appointment.is_paid,
    conclusion: {
      files: resolveFiles(
        appointment.appointment_conclusion?.prescriptions_portfolio?.media_portfolios,
      ),
      symptoms: appointment.appointment_conclusion?.symptoms,
      diagnosis: appointment.appointment_conclusion?.diagnosis,
      knownAllergies: appointment.appointment_conclusion?.known_allergies,
      treatment: appointment.appointment_conclusion?.treatment,
      ongoingTreatments: appointment.appointment_conclusion?.ongoing_treatments,
      recommendFutureAppointment: appointment.appointment_conclusion?.recommend_future_appointment,
      recommendations: appointment.appointment_conclusion?.recommendations,
      sentToPatient: appointment.appointment_conclusion?.sent_to_patient,
      updatedAt: appointment.appointment_conclusion?.updated_at,
      updatedAtFormatted: appointment.appointment_conclusion?.updated_at
        ? dayjs(appointment.appointment_conclusion.updated_at).format('YYYY-MM-DD HH:mm')
        : '',
    },
    price: appointment.price_as_string,
    priceAsString: appointment.price_as_string,
    priceGross: appointment.price_gross,
    priceGrossAsString: appointment.price_gross_as_string,
    voucher: resolveVoucher(appointment.voucher),
    duration: appointment.duration_in_minutes,
    realDurationAsString: appointment.duration_real_in_minutes,
    review: hasReviews
      ? { ...appointment.reviews[0], createdAt: appointment.reviews[0].created_at }
      : {},
    needReview: !hasReviews && ['call_ended', 'completed'].indexOf(appointment.status) > -1,
    medicalRecords: resolveRecords(appointment.appointment_patient_medical_records),
    sharedFiles: appointment.appointment_patient_medical_records
      ? appointment.appointment_patient_medical_records.map((row) => row.patient_medical_record?.id)
      : [],
    prescription: appointment.prescription,
  };
};

export { patientAppointmentsSelector, patientAppointmentDetailsSelector, resolveDoctorDetails };
