import { Route, Routes as ReactRouterDomRoutes } from 'react-router-dom';
import MainPage from 'shared/screens/MainPage';
import HowItWorksPatient from 'shared/screens/HowItWorksPatient';
import HowItWorksDoctor from 'shared/screens/HowItWorksDoctor';
import HomepageDoctor from 'shared/screens/homepageDoctor';
import ContactPage from 'shared/screens/ContactPage';
import AboutUsPage from 'shared/screens/AboutUsPage';
import SettingsTermsConditions from 'shared/screens/settings/SettingsTermsConditions';
import SettingsPrivacyPolicy from 'shared/screens/settings/SettingsPrivacyPolicy';
import PatientApp from 'patient/PatientApp';
import DoctorApp from 'doctor/DoctorApp';
import PageNotFound from 'shared/screens/PageNotFound';
import SettingsGDPR from 'shared/screens/settings/SettingsGDPR';
import PermissionsScreen from '../screens/auth/PermissionsScreen';
import MlmScreen from '../screens/mlm/MlmScreen';
import LinkRedirectScreen from '../screens/LinkRedirectScreen';
import PatientMainScreen from '../../patient/screens/main/PatientMainScreen';
import Partners from '../screens/Partners';
import SettingsCookiePolicy from '../screens/settings/SettingsCookiePolicy';
import SettingsCampaignRules from '../screens/settings/SettingsCampaignRules';

const PublicRoutes = [
  {
    path: '/',
    key: 'root',
    element: <PatientMainScreen />,
  },
  {
    path: 'doctor/landing',
    key: 'homepage_doctor',
    element: <HomepageDoctor />,
  },
  {
    path: 'patient/how-it-works',
    key: 'how_it_works_patient',
    element: <HowItWorksPatient />,
  },
  {
    path: 'doctor/how-it-works',
    key: 'how_it_works_doctor',
    element: <HowItWorksDoctor />,
  },
  {
    path: 'patient/partners',
    key: 'partners_patient',
    element: <Partners />,
  },
  {
    path: 'doctor/partners',
    key: 'partners_doctor',
    element: <Partners />,
  },
  {
    path: 'patient/mlm',
    key: 'mlm_patient',
    element: <MlmScreen />,
  },
  {
    path: 'doctor/mlm',
    key: 'mlm_doctor',
    element: <MlmScreen />,
  },
  {
    path: 'patient/descarca-aplicatia',
    key: 'link_redirect_patient',
    element: <LinkRedirectScreen />,
  },
  {
    path: 'doctor/descarca-aplicatia',
    key: 'link_redirect_doctor',
    element: <LinkRedirectScreen />,
  },
  {
    path: 'patient/about-us',
    key: 'about_us_patient',
    element: <AboutUsPage />,
  },
  {
    path: 'doctor/about-us',
    key: 'about_us_doctor',
    element: <AboutUsPage />,
  },
  {
    path: 'patient/contact',
    key: 'contact_patient',
    element: <ContactPage />,
  },
  {
    path: 'doctor/contact',
    key: 'contact_doctor',
    element: <ContactPage />,
  },
  {
    path: 'support/terms-and-conditions',
    key: 'terms_conditions',
    element: <SettingsTermsConditions />,
  },
  {
    path: 'support/privacy-policy',
    key: 'privacy_policy',
    element: <SettingsPrivacyPolicy />,
  },
  {
    path: 'support/cookie-policy',
    key: 'cookie-policy',
    element: <SettingsCookiePolicy />,
  },
  {
    path: 'support/campaign-rules',
    key: 'campaign-rules',
    element: <SettingsCampaignRules />,
  },
  {
    path: 'support/gdpr',
    key: 'gdpr',
    element: <SettingsGDPR />,
  },
  {
    path: 'support/permissions',
    key: 'PATIENT_ROOT_PERMISSIONS',
    element: <PermissionsScreen />,
  },
  {
    path: 'doctor/*',
    key: 'doctor',
    element: <DoctorApp />,
  },
  {
    path: 'patient/*',
    key: 'patient',
    element: <PatientApp />,
  },
];

const Routes = () => {
  return (
    <ReactRouterDomRoutes>
      {PublicRoutes.map((route) => (
        <Route key={route.key} path={route.path} element={route.element} />
      ))}
      {/* <Route path="*" element={<Navigate replace to="/404" />} /> */}
      {/* <Route path="/404" element={<PageNotFound />} /> */}
      <Route path="*" element={<PageNotFound fullPage />} />
    </ReactRouterDomRoutes>
  );
};

export { Routes };
