import { AnyAction } from 'redux';
import {
  PARTICIPANT_PLATFORM_ERROR,
  PARTICIPANT_PLATFORM_REQUEST,
  PARTICIPANT_PLATFORM_SUCCESS,
} from '../types/ParticipantPlatformTypes';

export const getParticipantPlatformRequest = (payload): AnyAction => ({
  type: PARTICIPANT_PLATFORM_REQUEST,
  payload,
});

export const getParticipantPlatformSuccess = (payload): AnyAction => ({
  type: PARTICIPANT_PLATFORM_SUCCESS,
  payload,
});

export const getParticipantPlatformError = (payload): AnyAction => ({
  type: PARTICIPANT_PLATFORM_ERROR,
  payload,
});
