import 'patient/styles/account-cards.css';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Modal } from 'react-bootstrap';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { applyVoucherRequest, removeVoucherRequest } from 'shared/redux/actions/VouchersActions';

const PaymentVoucherComponent = ({
  appointmentId,
  vouchers,
  serverVoucher,
  localChosenVoucher,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [voucherCode, setVoucherCode] = useState('');
  const [showModalApplyVoucher, setModalApplyVoucherShow] = useState(false);
  const modalApplyVoucherClose = () => setModalApplyVoucherShow(false);
  const modalApplyVoucherShow = () => setModalApplyVoucherShow(true);
  const [showModalVoucher, setModalVoucherShow] = useState(false);
  const modalVoucherClose = () => setModalVoucherShow(false);
  const modalVoucherShow = () => setModalVoucherShow(true);
  const removeVoucher = () => dispatch(removeVoucherRequest({ id: appointmentId }));
  const applyVoucher = (code) => {
    dispatch(
      applyVoucherRequest({
        id: appointmentId,
        data: { voucher: code },
      }),
    );
    modalApplyVoucherClose();
    modalVoucherClose();
  };

  return (
    <>
      <div className="voucher">
        <div className="subtitle">{t('settings.voucher')}</div>
        {!serverVoucher?.id ? (
          <>
            <button type="button" className="white-button" onClick={modalApplyVoucherShow}>
              <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Plus" />
              {t('settings.applyVoucher')}
            </button>
            {vouchers.length > 0 && (
              <div className="voucher-info pointer" onClick={modalApplyVoucherShow}>
                <CustomIcon
                  className="custom-icon"
                  color={Colors.medicColor}
                  size="24"
                  icon="Price"
                />
                {vouchers.length === 1 ? (
                  <>
                    {`${i18next.t('settings.eligibleSingleVoucher', {
                      count: vouchers.length ?? 0,
                    })}`}
                  </>
                ) : (
                  <>
                    {`${i18next.t('settings.eligibleMultipleVouchers', {
                      count: vouchers.length ?? 0,
                    })}`}
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <div className="voucher-box">
            <div className="details">
              <CustomIcon
                className="custom-icon"
                color={Colors.medicColor}
                size="28"
                icon="Price"
              />
              <div className="info">
                <div className="title">{localChosenVoucher?.name}</div>
                <div className="code">{localChosenVoucher?.code}</div>
              </div>
            </div>
            <button type="button" onClick={removeVoucher}>
              <CustomIcon
                className="custom-icon"
                color={Colors.mediumGrey}
                size="24"
                icon="Delete"
              />
            </button>
          </div>
        )}
      </div>

      <Modal
        show={showModalApplyVoucher}
        onHide={modalApplyVoucherClose}
        animation
        centered
        className="modal-apply-voucher"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{t('settings.applyVoucher')}</Modal.Title>
        </Modal.Header>
        <Modal.Header className="subheader">
          <button
            type="button"
            className="white-button"
            onClick={(event) => {
              modalVoucherShow();
              modalApplyVoucherClose();
            }}
          >
            <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Plus" />
            {t('settings.addVoucher')}
          </button>
        </Modal.Header>
        <Modal.Body>
          {vouchers.length > 0 && <div className="subtitle">{t('settings.myVouchers')}</div>}

          {vouchers?.map((voucher) => (
            <div className="voucher-box" key={voucher.id}>
              <CustomIcon className="custom-icon" color={Colors.darkGrey} size="28" icon="Price" />
              <div className="code">{voucher.code}</div>
              <div className="title">{voucher.name}</div>
              <div className="expires-at">Expiră la {voucher.dateEnd}</div>
              <div className="description">{voucher.shortDesc}</div>
              <button
                type="submit"
                className="orange-button"
                onClick={() => applyVoucher(voucher.code)}
              >
                {t('settings.applyTheVoucher')}
              </button>
            </div>
          ))}
        </Modal.Body>
      </Modal>

      <Modal
        show={showModalVoucher}
        onHide={modalVoucherClose}
        animation
        centered
        className="modal-voucher"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{t('settings.newVoucher')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="info-message">
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon="Warning_2"
            />
            {t('settings.newVoucherText')}
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Cod voucher"
            onChange={(e) => setVoucherCode(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="orange-button" onClick={() => applyVoucher(voucherCode)}>
            {t('settings.addTheVoucher')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentVoucherComponent;
