import 'doctor/styles/account.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import { requestLogout } from 'shared/redux/actions/AuthActions';
import i18next from 'i18next';
import DoctorAccountReviewsBlock from 'doctor/components/account/DoctorAccountReviewsBlock';
import DoctorAccountChangePasswordBlock from 'doctor/components/account/DoctorAccountChangePasswordBlock';
import DoctorAccountDeleteAccountBlock from 'doctor/components/account/DoctorAccountDeleteAccountBlock';
import DoctorAccountSettingsBlock from 'doctor/components/account/DoctorAccountSettingsBlock';
import DoctorAccountAssistanceBlock from 'doctor/components/account/DoctorAccountAssistanceBlock';
import DoctorAccountRatesBlock from 'doctor/components/account/DoctorAccountRatesBlock';
import DoctorAccountAvailabilityScheduleBlock from 'doctor/components/account/DoctorAccountAvailabilityScheduleBlock';
import { getAuthState, getUserProfileState } from 'shared/redux/src/StatesGetter';
import { useTranslation } from 'react-i18next';
import DoctorAccountContactBlock from '../../components/account/DoctorAccountContactBlock';

const DoctorAccount = () => {
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileData = useSelector(getUserProfileState, shallowEqual);

  const onLogOut = (e) => {
    e.preventDefault();
    dispatch(requestLogout({}));
  };

  // const [tabName, setTabName] = useState('');
  const [backDrop, setBackDrop] = useState('');

  // const activeTab = (e) => {
  //   if (e === 'settings') {
  //     setTabName('mobile-account settings-tab');
  //   } else if (e === 'personal-data') {
  //     setTabName('personal-data-tab');
  //   } else if (e === 'reviews') {
  //     setTabName('mobile-account reviews-tab');
  //   } else if (e === 'availabilitySchedule') {
  //     setTabName('mobile-account availabilitySchedule-tab');
  //   } else if (e === 'rates') {
  //     setTabName('mobile-account rates-tab');
  //   } else if (e === 'change-password') {
  //     setTabName('mobile-account change-password-tab');
  //   } else if (e === 'assistance') {
  //     setTabName('mobile-account assistance-tab');
  //   } else if (e === 'delete-account') {
  //     setTabName('mobile-account delete-account-tab');
  //   }
  // };

  // function removeTabName() {
  //   setTabName('');
  // }

  const onToggle = (e) => {
    console.log(e);
    if (e === true) {
      setBackDrop('dropdown-backdrop show');
    } else {
      setBackDrop('');
    }
  };

  return (
    <section className="account">
      <div className="title-section">
        <h1>{`${i18next.t('myAccount')}`}</h1>
      </div>
      <div>
        <Tab.Container id="account-tabs-menu" defaultActiveKey="choose-section">
          {/* <button type="button" onClick={removeTabName} className="back-to">
            <CustomIcon
              className="custom-icon"
              color={Colors.darkGrey}
              size="24"
              icon="Left_arrow"
            />
            {`${i18next.t('backToAccount')}`}
          </button> */}
          <div className="row rowp10">
            <div className="menu-box colp10">
              <div className="menu-account-mobile">
                <Dropdown onToggle={onToggle}>
                  <Dropdown.Toggle id="dropdown-basic">
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.darkGrey}
                      size="24"
                      icon="List"
                    />
                    Meniu cont
                    <CustomIcon
                      className="custom-icon rotate-90"
                      color={Colors.darkGrey}
                      size="24"
                      icon="Right_pointer"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Nav variant="tabs">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="settings"
                          className={`${profileData.isPharmacist ? 'is-pharmacist-only-item' : ''}`}
                        >
                          <div>
                            <div className="title">
                              <div>{`${i18next.t('settings.settings')}`}</div>
                              <div className="description">{`${i18next.t(
                                'settings.settingsDetails',
                              )}`}</div>
                            </div>
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.darkGrey}
                              size="28"
                              icon="Settings"
                            />
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    {!profileData.isPharmacist && (
                      <>
                        <Nav variant="tabs" className="small-nav single-nav">
                          <Nav.Item>
                            <Nav.Link eventKey="reviews">
                              <div>
                                <div className="title">
                                  <div>{`${i18next.t('reviews')}`}</div>
                                </div>
                                <CustomIcon
                                  className="custom-icon"
                                  color={Colors.darkGrey}
                                  size="28"
                                  icon="Ratings"
                                />
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Nav variant="tabs">
                          <Nav.Item>
                            <Nav.Link eventKey="availability-schedule">
                              <div>
                                <div className="title">
                                  <div>{`${i18next.t('mainScreenRoutes.availability')}`}</div>
                                  <div className="description">
                                    {`${i18next.t('mainScreenRoutes.availabilitySubtitle')}`}
                                  </div>
                                </div>
                                <CustomIcon
                                  className="custom-icon"
                                  color={Colors.darkGrey}
                                  size="28"
                                  icon="Calendar"
                                />
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="rates">
                              <div>
                                <div className="title">
                                  <div>{`${i18next.t('mainScreenRoutes.prices')}`}</div>
                                  <div className="description">
                                    {`${i18next.t('mainScreenRoutes.pricesSubtitle')}`}
                                  </div>
                                </div>
                                <CustomIcon
                                  className="custom-icon"
                                  color={Colors.darkGrey}
                                  size="28"
                                  icon="Price"
                                />
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </>
                    )}

                    <Nav variant="tabs" className="small-nav">
                      <Nav.Item>
                        <Nav.Link eventKey="change-password">
                          <div>
                            <div className="title">
                              <div>{`${i18next.t('changePassword')}`}</div>
                            </div>
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.darkGrey}
                              size="28"
                              icon="Password"
                            />
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="assistance">
                          <div>
                            <div className="title">
                              <div>{`${i18next.t('settings.support')}`}</div>
                            </div>
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.darkGrey}
                              size="28"
                              icon="Question_2"
                            />
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="delete-account">
                          <div>
                            <div className="title">
                              <div>{`${i18next.t('settings.deleteAccount')}`}</div>
                            </div>
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.darkGrey}
                              size="28"
                              icon="Delete"
                            />
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link onClick={onLogOut}>
                          <div>
                            <div className="title">
                              <div>{`${i18next.t('settings.logout')}`}</div>
                            </div>
                            <CustomIcon
                              className="custom-icon"
                              color={Colors.darkGrey}
                              size="28"
                              icon="Logout"
                            />
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Dropdown.Menu>
                </Dropdown>
                <div className={backDrop} />
              </div>
              <Nav variant="tabs">
                {!profileData.isPharmacist && (
                  <Nav.Item>
                    <Nav.Link eventKey="personal-data">
                      <div onClick={() => navigate('/doctor/account-profile/personal')}>
                        <div className="title">
                          <div>{`${i18next.t('profile')}`}</div>
                          <div className="description">{`${i18next.t(
                            'settings.myAccountDrDetails',
                          )}`}</div>
                        </div>
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.darkGrey}
                          size="28"
                          icon="Profil"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item>
                  <Nav.Link
                    eventKey="settings"
                    className={`${profileData.isPharmacist ? 'is-pharmacist-only-item' : ''}`}
                  >
                    <div>
                      <div className="title">
                        <div>{`${i18next.t('settings.settings')}`}</div>
                        <div className="description">{`${i18next.t(
                          'settings.settingsDetails',
                        )}`}</div>
                      </div>
                      <CustomIcon
                        className="custom-icon"
                        color={Colors.darkGrey}
                        size="28"
                        icon="Settings"
                      />
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {!profileData.isPharmacist && (
                <Nav variant="tabs" className="small-nav single-nav">
                  <Nav.Item>
                    <Nav.Link eventKey="reviews">
                      <div>
                        <div className="title">
                          <div>{`${i18next.t('reviews')}`}</div>
                        </div>
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.darkGrey}
                          size="28"
                          icon="Ratings"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              )}

              {!profileData.isPharmacist && (
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="availability-schedule">
                      <div>
                        <div className="title">
                          <div>{`${i18next.t('mainScreenRoutes.availability')}`}</div>
                          <div className="description">
                            {`${i18next.t('mainScreenRoutes.availabilitySubtitle')}`}
                          </div>
                        </div>
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.darkGrey}
                          size="28"
                          icon="Calendar"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="rates">
                      <div>
                        <div className="title">
                          <div>{`${i18next.t('mainScreenRoutes.prices')}`}</div>
                          <div className="description">
                            {`${i18next.t('mainScreenRoutes.pricesSubtitle')}`}
                          </div>
                        </div>
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.darkGrey}
                          size="28"
                          icon="Price"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              )}

              <Nav variant="tabs" className="small-nav">
                <Nav.Item>
                  <Nav.Link eventKey="change-password">
                    <div>
                      <div className="title">
                        <div>{`${i18next.t('changePassword')}`}</div>
                      </div>
                      <CustomIcon
                        className="custom-icon"
                        color={Colors.darkGrey}
                        size="28"
                        icon="Password"
                      />
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="assistance">
                    <div>
                      <div className="title">
                        <div>{`${i18next.t('settings.support')}`}</div>
                      </div>
                      <CustomIcon
                        className="custom-icon"
                        color={Colors.darkGrey}
                        size="28"
                        icon="Question_2"
                      />
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="contact">
                    <div>
                      <div className="title">
                        <div>{`${i18next.t('contact')}`}</div>
                      </div>
                      <CustomIcon
                        className="custom-icon"
                        color={Colors.darkGrey}
                        size="28"
                        icon="Mail"
                      />
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="delete-account">
                    <div>
                      <div className="title">
                        <div>{`${i18next.t('settings.deleteAccount')}`}</div>
                      </div>
                      <CustomIcon
                        className="custom-icon"
                        color={Colors.darkGrey}
                        size="28"
                        icon="Delete"
                      />
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={onLogOut}>
                    <div>
                      <div className="title">
                        <div>{`${i18next.t('settings.logout')}`}</div>
                      </div>
                      <CustomIcon
                        className="custom-icon"
                        color={Colors.darkGrey}
                        size="28"
                        icon="Logout"
                      />
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="content-box colp10">
              <Tab.Content>
                <Tab.Pane eventKey="choose-section">
                  <div className="empty-section d-none d-lg-block">
                    {t('settings.chooseSectionMyAccount')}
                  </div>
                  <div className="empty-section d-block d-lg-none">
                    {t('settings.chooseSectionMyAccountTop')}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="personal-data">personal-data</Tab.Pane>
                <Tab.Pane eventKey="settings">
                  <DoctorAccountSettingsBlock />
                </Tab.Pane>
                <Tab.Pane eventKey="reviews">
                  <DoctorAccountReviewsBlock />
                </Tab.Pane>
                <Tab.Pane eventKey="availability-schedule">
                  <DoctorAccountAvailabilityScheduleBlock />
                </Tab.Pane>
                <Tab.Pane eventKey="rates">
                  <DoctorAccountRatesBlock />
                </Tab.Pane>
                <Tab.Pane eventKey="change-password">
                  <DoctorAccountChangePasswordBlock />
                </Tab.Pane>
                <Tab.Pane eventKey="assistance">
                  <DoctorAccountAssistanceBlock />
                </Tab.Pane>
                <Tab.Pane eventKey="contact">
                  <DoctorAccountContactBlock />
                </Tab.Pane>
                <Tab.Pane eventKey="delete-account">
                  <DoctorAccountDeleteAccountBlock />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
      </div>
    </section>
  );
};

export default DoctorAccount;
