import 'patient/styles/auth.css';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Alerts from 'shared/components/Alerts';
import {
  confirmEmailRequest,
  requestLogout,
  resendTokenRequest,
} from 'shared/redux/actions/AuthActions';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { useQuery } from 'shared/modules/Hook';

const AccountActivation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const email = query.get('email')?.trim()?.replace(' ', '+') ?? '';
  const token = query.get('token')?.trim() ?? '';
  const [newToken, setNewToken] = useState('');
  const [resendCode, setResendCode] = useState(true);
  const activateAccount = (e) => {
    e.preventDefault();
    if (!newToken.length || !email.length) {
      Alerts.simpleAlert(t('error'), t('allFieldsMandatory'));
      return;
    }
    dispatch(confirmEmailRequest({ token: newToken, email }));
  };
  const resendToken = () => {
    setResendCode(!resendCode);
    dispatch(resendTokenRequest({}));
  };
  const onLogOut = (e) => {
    e.preventDefault();
    dispatch(requestLogout({}));
    navigate('/patient/login');
  };

  useEffect(() => {
    if (email.length && token.length) {
      dispatch(confirmEmailRequest({ token, email }));
    }
  }, [email, token]);

  return (
    <section className="account-activation">
      {/* {email.length && token.length ? ( */}
      <h2>{t('activatingAccount')}</h2>
      {/* ) : ( */}
      {/*  <form onSubmit={activateAccount}> */}
      {/*    <div className="title-section"> */}
      {/*      <h1>{t('token')}</h1> */}
      {/*    </div> */}

      {/*    <div className="info-message"> */}
      {/*      <CustomIcon */}
      {/*        className="custom-icon" */}
      {/*        color={Colors.mediumGrey} */}
      {/*        size="24" */}
      {/*        icon="Warning_2" */}
      {/*      /> */}
      {/*      {t('tokenText')} */}
      {/*    </div> */}
      {/*    <div className="form-input mb-2"> */}
      {/*      <input */}
      {/*        type="text" */}
      {/*        id="token" */}
      {/*        name="token" */}
      {/*        className="form-control" */}
      {/*        defaultValue={token} */}
      {/*        onChange={(event) => setNewToken(event.target.value)} */}
      {/*        placeholder=" " */}
      {/*      /> */}
      {/*      <label htmlFor="token">{t('token')}</label> */}
      {/*    </div> */}
      {/*    {!resendCode && ( */}
      {/*      <button type="button" className="white-button mb-3" onClick={resendToken}> */}
      {/*        {t('didNotGetToken')} */}
      {/*      </button> */}
      {/*    )} */}
      {/*    <button type="submit" className="orange-button mb-3"> */}
      {/*      {t('activationAccount')} */}
      {/*    </button> */}
      {/*    <div className="cancel-button" onClick={onLogOut}> */}
      {/*      <button type="button">{t('abort')}</button> */}
      {/*    </div> */}
      {/*  </form> */}
      {/* )} */}
    </section>
  );
};

export default AccountActivation;
