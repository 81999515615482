import 'patient/styles/account-my-vouchers.css';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { translatedDate } from 'shared/modules/DateTimeUtils';
import { useTranslation } from 'react-i18next';

const PatientAccountMyVouchersBlock = ({ vouchers }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="account-my-vouchers">
      <div className="title-section">
        <h2>{t('settings.myVouchers')}</h2>
      </div>
      {!vouchers.length && <div className="empty-section">{t('settings.noVouchers')}</div>}

      {vouchers.map((voucher) => {
        return (
          <div key={voucher.id} className="voucher-box">
            <CustomIcon className="custom-icon" color={Colors.darkGrey} size="28" icon="Price" />
            <div className="code">{voucher.code}</div>
            <div className="title">{voucher.name}</div>
            <div className="expires-at">
              {t('settings.expiresAt')} {translatedDate(voucher.dateEnd)}
            </div>
            <div className="discount">
              {t('discount')}: {voucher.discountAsString}
            </div>
            <div className="description">{voucher.campaign?.description} </div>
          </div>
        );
      })}
    </div>
  );
};

export default PatientAccountMyVouchersBlock;
