import 'patient/styles/consultations.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { getPatientAppointmentState } from 'shared/redux/src/StatesGetter';
import {
  appointmentCancelRequest,
  appointmentLocalStepsRequest,
  getPatientAppointmentDetailsRequest,
} from 'shared/redux/actions/PatientAppointmentActions';
import Alerts from 'shared/components/Alerts';
import { isAnyAppointmentInProgress } from 'shared/modules/PatientAppointmentUtils';
import { getPaymentMethodsRequest } from 'shared/redux/actions/PatientPaymentActions';
import Utils from 'shared/modules/Utils';
import PatientMedicCardButton from 'patient/screens/medicList/PatientMedicCardButton';
import { useTranslation } from 'react-i18next';
import infoCircleIcon from 'shared/assets/icons/info-circle-grey-icon.svg';
import React, { useState } from 'react';
import {
  fetchDoctorUpcomingAppointmentsRequest,
  getAppointmentDetailsRequest,
} from 'shared/redux/actions/DoctorAppointmentsActions';
import dayjs from 'dayjs';
import TimerComponent from '../TimerComponent';
import DoctorPushNotifier from '../../modules/DoctorPushNotifier';
import PatientPushNotifier from '../../modules/PatientPushNotifier';

const UpcomingConsultationListItem = ({ onCallNowClicked, listItem, isDoctor, user }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isAvailableForCallNow,
    specialization: { id: specializationId, name: specializationName },
  } = user;
  const {
    status: appointmentStatus,
    stickyStatus,
    id: currentAppointmentId,
  } = useSelector(getPatientAppointmentState, shallowEqual);
  const [disabledButton, setDisabledButton] = useState(false);

  const onCall = () => {
    if (isAnyAppointmentInProgress(appointmentStatus, stickyStatus)) {
      Alerts.simpleAlert(t('info'), t('validations.alreadyHasAppointment'));
      return;
    }
    if (isAvailableForCallNow) {
      onCallNowClicked(
        {
          ...user,
          specializationName,
          specialization: specializationName,
        },
        specializationId,
      );
    }
  };

  const deletePatientAppointment = () => {
    Alerts.okCancelAlert(t('warning'), t('validations.cancelAppointment'), () => {
      dispatch(appointmentCancelRequest({ appointmentId: listItem?.id }));
    });
  };

  const goToPay = () => {
    const { id: appointmentId, doctor, date, time, price, patient, duration } = listItem;

    if (
      isAnyAppointmentInProgress(appointmentStatus, stickyStatus) &&
      appointmentId !== currentAppointmentId
    ) {
      Alerts.simpleAlert(t('info'), t('validations.alreadyHasAppointment'));
      return;
    }
    dispatch(getPaymentMethodsRequest({}));
    dispatch(
      appointmentLocalStepsRequest({
        id: appointmentId,
        date,
        time,
        doctor: { ...doctor, specializationName: doctor.specialization.name },
        type: Utils.appointmentTypeMapper(listItem.appointmentType?.type),
        price,
        startTime: `${date} ${time}`,
        isActive: false,
        payment: {},
        patient,
        sharedFiles: listItem.sharedFiles,
        scheduledAt: date,
        duration,
      }),
    );

    navigate('/patient/appointment/summary-payment');
  };

  const joinAppointment = (e, item) => {
    e.preventDefault();

    if (!isDoctor) {
      dispatch(
        getPatientAppointmentDetailsRequest({
          id: listItem.id,
          updateLocal: true,
          alsoTwilio: true,
        }),
      );
      const dataPushPayload = {
        type: 'consultation_start_now_for_patient',
        metadata: {
          doctor: listItem?.doctor,
          appointment: {
            id: listItem?.id,
            specialization: listItem?.doctor.specialization.name,
          },
        },
        body: '',
        title: 'Consultația a început',
      };
      PatientPushNotifier.onForegroundPushReceived({
        data: { ...dataPushPayload, type: 'consultation_start_now_for_patient' },
      });
    } else {
      const dataPushPayload = {
        type: 'consultation_start_now_for_doctor',
        metadata: {
          appointment: {
            id: listItem?.id,
            timeStart: listItem?.startTime,
            timeEnd: listItem?.endTime,
            timeEndWithPause: listItem?.timeEndWithPause,
          },
        },
        body: t('patientIsWaiting', {
          firstName: user.firstName,
          lastName: user.lastName,
        }),
        title: 'Consultația a început',
        uuid: '',
      };
      DoctorPushNotifier.onForegroundPushReceived({
        data: { ...dataPushPayload, type: 'consultation_start_now_for_doctor' },
      });
    }
  };

  const renderJoinDoctorAppointmentButton = (appointment) => {
    return (
      <button
        type="button"
        onClick={(e) => {
          setDisabledButton(true);
          joinAppointment(e, appointment);
        }}
        className={`cursor-pointer green-button start-consultation-btn ${
          !Utils.rejoinableAppointment(
            appointment?.serverStatus,
            appointment?.date,
            appointment?.duration,
          )
            ? 'not-ready'
            : ''
        }`}
        disabled={disabledButton}
      >
        <CustomIcon
          className="custom-icon"
          color={
            !Utils.rejoinableAppointment(
              appointment?.serverStatus,
              appointment?.date,
              appointment?.duration,
            )
              ? Colors.mediumGrey
              : Colors.white
          }
          size="24"
          icon="Video"
        />
        {Utils.timeUntil(appointment.date).hours === 0 ? (
          <>
            {t('appointments.startAppointmentIn')}
            <div className="timer">
              <TimerComponent
                action={() => {}}
                endTime={appointment?.date}
                onTimeAboutToEnd={() => {}}
              />
            </div>
          </>
        ) : (
          t('appointments.startAppointment')
        )}
      </button>
    );
  };

  const goToAppointment = (e, appointmentId) => {
    e.preventDefault();

    if (isDoctor) {
      dispatch(getAppointmentDetailsRequest({ id: appointmentId }));
      navigate(`/doctor/consultation-details/${appointmentId}`, {
        state: {
          templateType: 'future',
        },
      });
    } else {
      navigate(`/patient/consultations/consultation-details/${appointmentId}`);
    }
  };

  const renderSubTitle = (appointment) => {
    if (appointment?.medicalRecords?.length) {
      return (
        <div className="year-files">
          {Utils.calculateAge(appointment?.patient?.birthDate) > 0 &&
            `${Utils.calculateAge(appointment?.patient?.birthDate)} ${t('years')} • `}
          <CustomIcon className="custom-icon" color={Colors.mediumGrey} size="15" icon="Document" />
          {t('attachedFiles')}
        </div>
      );
    }
    if (Utils.calculateAge(appointment?.patient?.birthDate) > 0) {
      return (
        <div className="year-files">{`${Utils.calculateAge(appointment?.patient?.birthDate)} ${t(
          'years',
        )}`}</div>
      );
    }
    return null;
  };

  return (
    <div className="box upcoming">
      <div className="medic-info">
        <div className="data">{dayjs(listItem.date).format('HH:mm')}</div>

        <div
          className={`${
            !isDoctor && !listItem.isPaid && listItem?.type !== 'callSpecialist' && 'need-to-pay'
          } details`}
        >
          <div
            className={`${
              !isDoctor && !listItem.isPaid && listItem?.type !== 'callSpecialist' && 'need-to-pay'
            } ${
              isDoctor && !listItem?.isNextScheduledAppointment && 'need-to-pay'
            } left-column cursor-pointer`}
            style={listItem?.type === 'callSpecialist' ? { width: '100%' } : {}}
            onClick={(e) => goToAppointment(e, listItem.id)}
          >
            {!isDoctor && listItem?.type === 'callSpecialist' && !user?.id ? (
              <>
                <img
                  src={infoCircleIcon}
                  alt="doctor not found"
                  style={{
                    width: '36px',
                    height: '36px',
                    marginRight: '20px',
                  }}
                />
                <div className="info">
                  <div className="fullName">{t('callSpecialistDoctorSearching')}</div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="avatar"
                  style={
                    user?.imgUrl
                      ? { backgroundImage: `url(${user?.imgUrl})` }
                      : { backgroundColor: '#E5E7E8' }
                  }
                >
                  {!user?.imgUrl && (
                    <div className="image-placeholder">{`${user?.firstName?.charAt(
                      0,
                    )}${user?.lastName?.charAt(0)}`}</div>
                  )}
                </div>
                <div className="info">
                  <div className="fullName">{user?.fullNameWithTitle}</div>
                  <div className="specialization">
                    {isDoctor ? renderSubTitle(listItem) : user?.specialization?.name}
                  </div>
                  <div className="type">
                    {user?.specialization?.name} •{' '}
                    {Utils.capitalizeFirstLetter(t(listItem.service.slug))}
                  </div>
                </div>
              </>
            )}
            <CustomIcon
              className="custom-icon arrow-right"
              color={Colors.darkGrey}
              size="24"
              icon="Right_pointer"
            />
          </div>

          {isDoctor && listItem?.isNextScheduledAppointment && (
            <div className="right-column">
              <div className="actions">{renderJoinDoctorAppointmentButton(listItem)}</div>
            </div>
          )}

          {!isDoctor && listItem?.type !== 'callSpecialist' && (
            <>
              <div
                className={`${
                  !isDoctor && !listItem.isPaid ? 'right-column need-to-pay' : 'right-column'
                }`}
              >
                {!isDoctor && !listItem.isPaid ? (
                  <div className="info-message">
                    <CustomIcon
                      className="custom-icon"
                      color={Colors.patientColor}
                      size="24"
                      icon="Warning_2"
                    />
                    <div className="text">
                      {listItem.paymentLimitText.length > 0
                        ? listItem.paymentLimitText
                        : t('appointments.reservationInfo')}
                    </div>
                  </div>
                ) : (
                  <div className="actions">
                    {Utils.rejoinableAppointment(
                      listItem?.serverStatus,
                      listItem?.date,
                      listItem?.duration,
                    ) ? (
                      <button
                        type="button"
                        onClick={(e) => joinAppointment(e, listItem)}
                        className="medic-action-btn resume-consultation-btn green-button"
                      >
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.white}
                          size="24"
                          icon="Video"
                        />
                        {t('appointments.joinAppointment')}
                      </button>
                    ) : (
                      <>
                        {listItem?.type !== 'callNow' && (
                          <button
                            type="button"
                            onClick={() => {
                              setDisabledButton(true);
                              deletePatientAppointment();
                            }}
                            className="cancel-btn"
                            disabled={disabledButton}
                          >
                            {t('abort')}
                          </button>
                        )}
                        <PatientMedicCardButton
                          status={user?.availability}
                          onClickEvent={onCall}
                          upcomingConsultation
                          listItemType={listItem?.type}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
              {!isDoctor && !listItem.isPaid ? (
                <div className="actions pay mb-md-4">
                  <button type="button" onClick={deletePatientAppointment} className="cancel-btn">
                    {t('abort')}
                  </button>
                  <button type="button" onClick={goToPay} className="orange-button">
                    {t('goToPay')}
                  </button>
                </div>
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpcomingConsultationListItem;
