import 'doctor/styles/account-assistance.css';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { supportFormRequest } from 'shared/redux/actions/SupportActions';
import Alerts from 'shared/components/Alerts';
import { useTranslation } from 'react-i18next';
import { isDoctor } from '../../../shared/services/EnvService';
import {
  getPatientProfileState,
  getUserProfileState,
} from '../../../shared/redux/src/StatesGetter';

const DoctorAccountContactBlock = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profileData = useSelector(getUserProfileState, shallowEqual);

  const [firstName, setFirstName] = useState(profileData?.firstName);
  const [lastName, setLastName] = useState(profileData?.lastName);
  const [email, setEmail] = useState(profileData?.email);
  const [phoneNumber, setPhoneNumber] = useState(profileData?.phoneNumber);
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [showModalContact, setModalContactShow] = useState(false);
  const modalContactClose = () => {
    setModalContactShow(false);
    setSubject('');
    setContent('');
  };
  const modalContactShow = () => setModalContactShow(true);
  const onPress = () => {
    if (!subject || !content) {
      Alerts.simpleAlert(`${t('error')}`, `${t('allFieldsMandatory')}`);
      return;
    }
    dispatch(supportFormRequest({ firstName, lastName, email, phoneNumber, subject, content }));
    modalContactClose();
  };
  return (
    <div className="account-assistance">
      <div className="title-section">
        <h2>{t('contactPage.contactInformation')}</h2>
      </div>
      <div className="contact">
        <div className="border-box">
          <div className="subtitle">{t('contactPage.applicationProgram')}</div>
          <div className="col-12 pt-4 pt-md-0">
            <div className="contact-info-text">{`${t('day_1')} - ${t('day_7')}: 8:00 - 24:00`}</div>
            {/* <div className="contact-info-text">contact@ringdoc.ro</div> */}
            <div className="subtitle mt-4">{t('support.support')}</div>
            <div className="contact-info-text">
              0758 885 375
              <br />
              contact@ringdoc.ro
              <br />
              {`${t('day_1')} - ${t('day_5')}: 9:00 - 17:00`}
            </div>

            <div className="subtitle mt-4">{t('address')}</div>
            <div className="contact-info-text">
              Alo Doc App SRL
              <br />
              Calea Floreasca 169A, Cladirea A, Unitatea A.3.3, Et. 3, Sector. 1, Bucuresti 020285,
              Romania
            </div>
          </div>
        </div>
        <button type="button" className="white-button" onClick={modalContactShow}>
          {t('support.supportForm')}
          <CustomIcon
            className="custom-icon"
            color={Colors.darkGrey}
            size="24"
            icon="Right_pointer"
          />
        </button>
      </div>

      <Modal
        show={showModalContact}
        onHide={modalContactClose}
        animation
        centered
        className="modal-contact medic-modal"
      >
        <Modal.Header closeButton closeLabel={t('close2')}>
          <Modal.Title>{t('contact')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="info-message">
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon="Warning_2"
            />
            {t('contactText')}
          </div>
          <form>
            <div className="form-input">
              <input
                autoComplete="off"
                id="firstName"
                name="firstName"
                type="text"
                className="form-control"
                placeholder=" "
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
              <label htmlFor="firstName">{t('firstName')}</label>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="lastName"
                name="lastName"
                type="text"
                className="form-control"
                placeholder=" "
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
              <label htmlFor="lastName">{t('lastName')}</label>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="email"
                name="email"
                type="text"
                className="form-control"
                placeholder=" "
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <label htmlFor="email">{t('email')}</label>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                className="form-control"
                placeholder=" "
                value={phoneNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
              />
              <label htmlFor="phoneNumber">{t('phoneNumber')}</label>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="subject"
                name="subject"
                type="text"
                className="form-control"
                placeholder=" "
                onChange={(event) => setSubject(event.target.value)}
              />
              <label htmlFor="subject">{t('support.subject')}</label>
            </div>
            <div className="form-textarea">
              <textarea
                id="message"
                name="message"
                className="form-control"
                placeholder=" "
                rows={5}
                onChange={(event) => setContent(event.target.value)}
              />
              <label htmlFor="message">{t('support.message')}</label>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="green-button" onClick={onPress}>
            {t('send')}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DoctorAccountContactBlock;
