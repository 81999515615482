import 'shared/styles/mlm.css';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { Link, useNavigate } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import _ from 'lodash';
import { isDoctor, mainContentAlignCenter } from '../../services/EnvService';
import FooterSection from '../../components/FooterSection';
import {
  getAllDoctorsMlmState,
  getAuthState,
  getPatientProfileState,
  referralState,
} from '../../redux/src/StatesGetter';
import { forceLogoutRequest } from '../../redux/actions/AuthActions';
import {
  fetchDoctorsAllIndexRequest,
  setReferralRequest,
} from '../../redux/actions/ReferralsActions';
import { createLoadingSelector } from '../../redux/src/GenericSeletors';
import LoadingSpinner from '../../components/LoadingSpinner';

const MlmScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [doctorNameSearch, setDoctorNameSearch] = useState('');
  const [selectedDr, setSelectedDr] = useState('');
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const patientProfile = useSelector(getPatientProfileState, shallowEqual);
  const loadingSelector = createLoadingSelector();
  const isLoading = useSelector(loadingSelector);
  const [showLoader, setShowLoader] = useState(false);

  const onLogOut = (e) => {
    e.preventDefault();
    dispatch(forceLogoutRequest({}));

    if (isLoggedIn) {
      if (isDoctor(true)) {
        navigate('/doctor/login');
      } else {
        navigate('/patient/login');
      }
    }
    if (!isLoggedIn) {
      if (isDoctor(true)) {
        navigate('/doctor/login');
      } else {
        navigate('/patient/login');
      }
    }
  };

  const { data: allDoctors } = useSelector(getAllDoctorsMlmState, shallowEqual);

  const [listingDoctors, setListingDoctors] = useState({});

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (doctorNameSearch.length > 2) {
      setSelectedDr('');
      const delayDebounceFn = setTimeout(() => {
        dispatch(fetchDoctorsAllIndexRequest({ doctorNameSearch }));
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
    // setListingDoctors({});
  }, [doctorNameSearch]);

  useEffect(() => {
    setListingDoctors(allDoctors);
  }, [allDoctors]);

  const handleReferralSubmit = (e) => {
    e.preventDefault();

    if (isDoctor(true)) {
      sessionStorage.setItem('doctorWantMLM', String(false));
    } else {
      sessionStorage.setItem('patientWantMLM', String(false));
    }

    dispatch(setReferralRequest({ referrer: selectedDr }));
  };

  const refuseMlm = () => {
    if (isDoctor(true)) {
      sessionStorage.setItem('doctorWantMLM', String(false));
      if (patientProfile.isUnconfirmedFromSocial) {
        navigate('/doctor/account');
      } else {
        navigate('/doctor/home');
      }
    } else {
      sessionStorage.setItem('patientWantMLM', String(false));
      if (patientProfile.isUnconfirmedFromSocial) {
        navigate('/patient/account');
      } else {
        navigate('/');
      }
    }
  };

  const DoctorTag = () => <div id="isDoctorApp" />;

  useEffect(() => {
    setShowLoader(isLoading);
  }, [isLoading]);

  return (
    <div className="d-flex flex-column min-vh-100">
      {showLoader && <LoadingSpinner isLoading={isLoading} />}
      {isDoctor(true) && <DoctorTag />}
      <Menu right burgerButtonClassName="mobile-menu">
        <ul>
          <li className="login">
            <Link className="cursor-pointer" to="/" onClick={onLogOut}>
              {t('logoutButton')}
              <CustomIcon
                className="custom-icon"
                color={Colors.darkGrey}
                size="20"
                icon="Right_pointer"
              />
            </Link>
          </li>
        </ul>
      </Menu>

      <div className="container-fluid d-flex flex-column min-vh-100">
        <div className="row d-flex flex-column min-vh-100">
          <header className="menu landing">
            <div className="container">
              <div className="row">
                <div className="view login-register-menu green-menu">
                  <Link className="logo cursor-pointer" to={isDoctor(true) ? '/doctor/home' : '/'}>
                    <i
                      className={isDoctor(true) ? 'ringdoc-medic-color-logo' : 'ringdoc-color-logo'}
                    />
                  </Link>
                  <div className="menu">
                    <ul>
                      <li className="login">
                        <Link to="/" onClick={onLogOut}>
                          {t('logoutButton')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <main
            className={`${
              mainContentAlignCenter()
                ? 'container-fluid mt-auto mb-auto'
                : 'container-fluid mb-auto'
            }`}
          >
            <section className="mlm-auth">
              <form onSubmit={handleReferralSubmit}>
                <div className="title-section">
                  {isDoctor(true) ? (
                    <p>{t('mlm.ifRecommendedYouDoctorText')}</p>
                  ) : (
                    <p>{t('mlm.ifRecommendedYouPatientText')}</p>
                  )}
                </div>
                <div className="searchbar">
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.mediumGrey}
                    size="20"
                    icon="Search"
                  />
                  <input
                    type="text"
                    placeholder={
                      isDoctor(true) ? t('mlm.searchDoctorName') : t('mlm.searchDoctorNamePatient')
                    }
                    value={doctorNameSearch}
                    onChange={(e) => {
                      setListingDoctors({});
                      setDoctorNameSearch(e.target.value);
                    }}
                  />
                </div>
                <div className="searchbar-tooltip">
                  <div className="input-tooltip">{t('mlm.searchBarTooltip')}</div>
                </div>

                <div className="content">
                  {/* when you find results on search */}
                  {doctorNameSearch.length > 2 &&
                    listingDoctors &&
                    Object.entries(listingDoctors).map(([, doctor]) => {
                      return (
                        <div className="profile-box" key={_.uniqueId()}>
                          <div
                            className={
                              isDoctor(true)
                                ? 'custom-control custom-radio green-radio align-center-radio active-label-green'
                                : 'custom-control custom-radio orange-radio align-center-radio active-label-orange'
                            }
                          >
                            <input
                              className="custom-control-input"
                              // @ts-ignore
                              id={`radio_${doctor.id}`}
                              // @ts-ignore
                              name={`radio_${doctor.id}`}
                              type="radio"
                              // @ts-ignore
                              onClick={() => setSelectedDr(doctor.id)}
                              // @ts-ignore
                              checked={selectedDr === doctor.id}
                              onChange={() => {}}
                            />
                            {/* @ts-ignore */}
                            <label htmlFor={`radio_${doctor.id}`} className="custom-control-label">
                              <div
                                className="avatar"
                                style={
                                  // @ts-ignore
                                  doctor?.imgUrl
                                    ? // @ts-ignore
                                      { backgroundImage: `url(${doctor?.imgUrl})` }
                                    : { backgroundColor: '#E5E7E8' }
                                }
                              >
                                {
                                  // @ts-ignore
                                  !doctor?.imgUrl && (
                                    // @ts-ignore
                                    <div className="image-placeholder">{`${doctor?.firstName?.charAt(
                                      0,
                                      // @ts-ignore
                                    )}${doctor?.lastName?.charAt(0)}`}</div>
                                  )
                                }
                                {/* @ts-ignore */}
                                <i className={`status ${doctor.availability} `} />
                              </div>
                              <div className="info">
                                {/* @ts-ignore */}
                                <div className="title text-left">{doctor.name}</div>
                                <div className="details text-left">
                                  {/* @ts-ignore */}
                                  {doctor.specializations.map((specialization, index) => {
                                    return (
                                      <span key={_.uniqueId()}>
                                        {`${specialization}${
                                          // @ts-ignore
                                          doctor.specializations.length - 1 === index ? '' : ', '
                                        } `}{' '}
                                      </span>
                                    );
                                  })}
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                      );
                    })}

                  {doctorNameSearch.length > 2 ? (
                    <>
                      {selectedDr !== '' && (
                        <button
                          type="submit"
                          className={isDoctor(true) ? 'green-button mt-3' : 'orange-button mt-3'}
                        >
                          {isDoctor(true) ? t('mlm.continueDoctorBtn') : t('finish')}
                        </button>
                      )}
                      {selectedDr === '' && (
                        <div className="cancel-button" onClick={refuseMlm}>
                          <button type="button">
                            {isDoctor(true)
                              ? t('mlm.goAheadDoctorBtn')
                              : t('mlm.goAheadPatientBtn')}
                          </button>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <button type="button" className="white-button" onClick={refuseMlm}>
                        {isDoctor(true) ? t('mlm.goAheadDoctorBtn') : t('mlm.goAheadPatientBtn')}
                      </button>
                      <div className="mlm-search-icon">
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.statusOffline}
                          size="160"
                          icon="Search_doctor_illustration"
                        />
                      </div>
                    </>
                  )}
                </div>
              </form>
            </section>
            <div className="push" />
          </main>

          <FooterSection />
        </div>
      </div>
    </div>
  );
};

export default MlmScreen;
