import i18next from 'i18next';
import {
  appointmentScheduleRequest,
  callNowAppointmentRequest,
  callSpecialistAppointmentRequest,
} from 'shared/redux/actions/PatientAppointmentActions';
import { storeDispatcher, storeStateGetter } from 'shared/redux/ReduxStore';
import Alerts from 'shared/components/Alerts';
import NavigationService from 'shared/services/NavigationService';
import dayjs from 'dayjs';

export const getStartAndEndTime = (appointmentType) => {
  const appointmentSettings = storeStateGetter('appointmentSettingsState')[appointmentType];
  const { startTime, endTime } = appointmentSettings;
  return { startTime, endTime };
};

export const withinTheTime = (appointmentType) => {
  if (['callNow', 'programmed', 'callSpecialist'].indexOf(appointmentType) > -1) return true;
  try {
    const { startTime, endTime } = getStartAndEndTime(appointmentType);
    const todayDate = dayjs().format('YYYY-MM-DD');
    const startTimeStamp = dayjs(`${todayDate} ${startTime}:00`).valueOf();
    const endTimestamp = dayjs(`${todayDate} ${endTime}:00`).valueOf();
    const nowTimeStamp = dayjs().valueOf();
    if (startTimeStamp > nowTimeStamp || endTimestamp < nowTimeStamp) {
      Alerts.simpleAlert(`${i18next.t('error')}`, `${i18next.t('appointmentOutOfTime')}`);
      return false;
    }
    return true;
  } catch {
    return false;
  }
};

export const isAnyAppointmentInProgress = (appointmentStatus, stickyStatus) => {
  return (
    appointmentStatus === 'inProgress' ||
    ['SearchDoctor', 'WaitForDoctor'].indexOf(stickyStatus) > -1
  );
};

export const allowNewAppointment = (appointmentStatus, stickyStatus) => {
  const { isLoggedIn } = storeStateGetter('authState');
  if (!isLoggedIn) {
    Alerts.okCancelAlert(i18next.t('info'), i18next.t('alerts.youNeedToLogin'), () => {
      localStorage.setItem('CURRENT_LINK', `${window.location.pathname}`);
      NavigationService.navigate('/patient/login');
    });
    return false;
  }
  if (isAnyAppointmentInProgress(appointmentStatus, stickyStatus)) {
    Alerts.simpleAlert(i18next.t('info'), i18next.t('validations.alreadyHasAppointment'));
    return false;
  }
  return true;
};

export const requestAppointment = (appointmentType, payload) => {
  if (!withinTheTime(appointmentType)) {
    return;
  }
  switch (appointmentType) {
    case 'callNow':
      storeDispatcher(callNowAppointmentRequest(payload));
      break;
    case 'programmed':
      storeDispatcher(appointmentScheduleRequest(payload));
      break;
    case 'callSpecialist':
      storeDispatcher(callSpecialistAppointmentRequest(payload));
      break;
    case 'freeTalk':
      // storeDispatcher(freeTalkRequest({}));
      break;
    default:
      break;
  }
};
