import 'shared/styles/static-pages.css';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import FooterSection from 'shared/components/FooterSection';
import HeaderMenu from '../../components/HeaderMenu';

const SettingsCookiePolicy = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const anchor = window.location.hash.slice(1);
    setTimeout(() => {
      if (anchor) {
        const anchorEl = document.getElementById(anchor);
        if (anchorEl) {
          anchorEl.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 1000);
  }, []);

  return (
    <div className="privacy-page">
      <Helmet>
        <title>{t('cookie-policy.title')}</title>
        <meta name="description" content="POLITICA DE COOKIE" />
      </Helmet>

      <div className="container-fluid">
        <div className="row">
          <HeaderMenu />

          <main className="container">
            <section className="static-pages">
              <div className="title-section">
                <h1>{t('cookie-policy.title')}</h1>
              </div>

              <div>
                <strong>{t('cookie-policy.about-us.title')}</strong>
                <p className="mt-4">
                  {t('cookie-policy.about-us.p1-section1')}{' '}
                  <a href="https://www.ringdoc.ro">www.ringdoc.ro</a>{' '}
                  {t('cookie-policy.about-us.p1-section2')}
                </p>

                <p className="mt-5">
                  <strong>{t('cookie-policy.purpose.title')}</strong>
                </p>
                <p className="mt-3">{t('cookie-policy.purpose.p1')}</p>

                <p className="mt-5">
                  <strong>{t('cookie-policy.applicability.title')}</strong>
                </p>
                <p className="mt-3">{t('cookie-policy.applicability.p1')}</p>

                <p className="mt-5">
                  <strong>{t('cookie-policy.definitions.title')}</strong>
                </p>
                <ul className="mt-3">
                  <li>{t('cookie-policy.definitions.p1-li1')}</li>
                  <li>{t('cookie-policy.definitions.p1-li2')}</li>
                  <li>{t('cookie-policy.definitions.p1-li3')}</li>
                  <li>{t('cookie-policy.definitions.p1-li4')}</li>
                  <li>{t('cookie-policy.definitions.p1-li5')}</li>
                  <li>{t('cookie-policy.definitions.p1-li6')}</li>
                  <li>{t('cookie-policy.definitions.p1-li7')}</li>
                  <li>
                    {t('cookie-policy.definitions.p1-li8')}
                    <a href="https://www.ringdoc.ro">www.ringdoc.ro</a>;
                  </li>
                  <li>{t('cookie-policy.definitions.p1-li9')}</li>
                </ul>

                <p className="mt-5">
                  <strong>{t('cookie-policy.explaining-cookies.title')}</strong>
                </p>
                <p className="mt-3">{t('cookie-policy.explaining-cookies.p1')}</p>
                <p>{t('cookie-policy.explaining-cookies.p2')}</p>
                <p>{t('cookie-policy.explaining-cookies.p3')}</p>
                <p>
                  <strong>{t('cookie-policy.explaining-cookies.p4-t1')}</strong>
                </p>
                <ul>
                  <li>
                    <em>{t('cookie-policy.explaining-cookies.p4-li1-em')}</em>
                    {t('cookie-policy.explaining-cookies.p4-li1')}
                  </li>
                  <li>
                    <em>{t('cookie-policy.explaining-cookies.p4-li2-em')}</em>
                    {t('cookie-policy.explaining-cookies.p4-li2')}
                  </li>
                  <li>
                    <em>{t('cookie-policy.explaining-cookies.p4-li3-em')}</em>
                    {t('cookie-policy.explaining-cookies.p4-li3')}
                  </li>
                </ul>

                <p className="mt-5">
                  <strong>{t('cookie-policy.type-of-cookies-based-on-ownership.title')}</strong>
                </p>
                <p className="mt-3">{t('cookie-policy.type-of-cookies-based-on-ownership.p1')}</p>
                <p>{t('cookie-policy.type-of-cookies-based-on-ownership.p2')}</p>
                <p>{t('cookie-policy.type-of-cookies-based-on-ownership.p3')}</p>
                <p>{t('cookie-policy.type-of-cookies-based-on-ownership.p4')}</p>

                <p className="mt-5">
                  <strong>
                    {t('cookie-policy.types-of-cookies-based-on-their-purpose.title')}
                  </strong>
                </p>
                <p className="mt-3">
                  {t('cookie-policy.types-of-cookies-based-on-their-purpose.p1')}
                </p>
                <p>{t('cookie-policy.types-of-cookies-based-on-their-purpose.p2')}</p>
                <p>
                  <strong>
                    {t('cookie-policy.types-of-cookies-based-on-their-purpose.p3-section1')}
                  </strong>
                  {t('cookie-policy.types-of-cookies-based-on-their-purpose.p3-section2')}
                </p>
                <p>
                  <strong>
                    {t('cookie-policy.types-of-cookies-based-on-their-purpose.p4-section1')}
                  </strong>
                  {t('cookie-policy.types-of-cookies-based-on-their-purpose.p4-section2')}
                </p>
                <p>
                  <strong>
                    {t('cookie-policy.types-of-cookies-based-on-their-purpose.p5-section1')}
                  </strong>
                  {t('cookie-policy.types-of-cookies-based-on-their-purpose.p5-section2')}
                </p>
                <p>
                  <strong>
                    {t('cookie-policy.types-of-cookies-based-on-their-purpose.p6-section1')}
                  </strong>
                  {t('cookie-policy.types-of-cookies-based-on-their-purpose.p6-section2')}
                </p>
                <p>
                  <strong>
                    {t('cookie-policy.types-of-cookies-based-on-their-purpose.p7-section1')}
                  </strong>
                  {t('cookie-policy.types-of-cookies-based-on-their-purpose.p7-section2')}
                </p>
                <p>
                  <strong>
                    {t('cookie-policy.types-of-cookies-based-on-their-purpose.p8-section1')}
                  </strong>
                  {t('cookie-policy.types-of-cookies-based-on-their-purpose.p8-section2')}
                </p>
                <p>
                  <strong>
                    {t('cookie-policy.types-of-cookies-based-on-their-purpose.p9-section1')}
                  </strong>
                  {t('cookie-policy.types-of-cookies-based-on-their-purpose.p9-section2')}
                </p>

                <p className="mt-5">
                  <strong>{t('cookie-policy.type-of-cookies-based-on-lifetime.title')}</strong>
                </p>
                <p className="mt-3">{t('cookie-policy.type-of-cookies-based-on-lifetime.p1')}</p>
                <p>{t('cookie-policy.type-of-cookies-based-on-lifetime.p2')}</p>
                <p className="mt-5">
                  <strong>{t('cookie-policy.cookies-used-on-our-site.title')}</strong>
                </p>
                <p className="mt-3">{t('cookie-policy.cookies-used-on-our-site.p1')}</p>
                <p>{t('cookie-policy.cookies-used-on-our-site.p2')}</p>
                <p>{t('cookie-policy.cookies-used-on-our-site.p3')}</p>
                <p>{t('cookie-policy.cookies-used-on-our-site.p4')}</p>

                {/* <!-- OneTrust Cookies List start --> */}
                <div id="ot-sdk-cookie-policy" />
                {/* <!-- OneTrust Cookies List end --> */}

                <p className="mt-5">
                  <strong>{t('cookie-policy.blocking-and-deleting-cookies.title')}</strong>
                </p>
                <p>{t('cookie-policy.blocking-and-deleting-cookies.p1')}</p>
                <p>{t('cookie-policy.blocking-and-deleting-cookies.p2')}</p>
                <ul>
                  <li>
                    <a href="https://support.google.com/chrome/answer/95647?hl=ro&amp;sjid=13731546166339545029-EU">
                      {t('cookie-policy.blocking-and-deleting-cookies.p3-section1')}
                    </a>
                  </li>
                  <li>
                    <a href="https://support.mozilla.org/ro/kb/activarea-si-dezactivarea-cookie-urilor">
                      {t('cookie-policy.blocking-and-deleting-cookies.p3-section2')}
                    </a>
                  </li>
                </ul>

                <p className="mt-5">
                  <strong>{t('cookie-policy.updates.title')}</strong>
                </p>
                <p className="mt-3">{t('cookie-policy.updates.p1')}</p>
                <p className="mt-5">
                  <strong>{t('cookie-policy.contact.title')}</strong>
                </p>
                <p className="mt-3">{t('cookie-policy.contact.p1')}</p>
                <ul>
                  <li>E-mail: dpo@ringdoc.ro</li>
                  <li>Formular website: https://ringdoc.ro/patient/contact</li>
                  <li>Telefon: 0040.758.885.375</li>
                  <li>
                    Adresa postala: Mun. București, Sector 1, Calea Floreasca Nr. 169A, Clădirea A,
                    Et. 3, Unitatea A3.3, cu mențiunea “in atenția responsabilului Ring Doc cu
                    protecția datelor”.
                  </li>
                </ul>
              </div>
            </section>
          </main>

          <FooterSection />
        </div>
      </div>
    </div>
  );
};

export default SettingsCookiePolicy;
