import 'patient/styles/appointment-steps.css';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import {
  getAuthState,
  getDoctorTimeSlotsState,
  getPatientAppointmentState,
} from 'shared/redux/src/StatesGetter';
import { getDoctorTimeSlotsRequest } from 'shared/redux/actions/DoctorsActions';
import { requestAppointment } from 'shared/modules/PatientAppointmentUtils';
import Alerts from 'shared/components/Alerts';
import dayjs from 'dayjs';

const PatientAppointmentSetDate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const { doctor, specializationId, noOfSteps, service, hasControl } = useSelector(
    getPatientAppointmentState,
    shallowEqual,
  );
  const doctorTimeSlotsState = useSelector(getDoctorTimeSlotsState, shallowEqual);
  const { pictureMedia, firstName, lastName, fullNameWithTitle, rawFirstAvailableTime } = doctor;
  const [value, onChange] = useState(
    new Date(
      Number(
        rawFirstAvailableTime
          ? dayjs(rawFirstAvailableTime).format('YYYY')
          : dayjs().format('YYYY'),
      ),
      Number(
        rawFirstAvailableTime ? dayjs(rawFirstAvailableTime).format('M') : dayjs().format('M'),
      ) - 1,
      Number(
        rawFirstAvailableTime ? dayjs(rawFirstAvailableTime).format('D') : dayjs().format('D'),
      ),
    ),
  );
  const [availableHours, setAvailableHours] = useState([]);
  const [selectedTime, setSelectedTime] = useState({ time: '', idx: -1 });
  const [selectedDate, setSelectedDate] = useState(
    rawFirstAvailableTime
      ? dayjs(rawFirstAvailableTime).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD'),
  );
  const [selectedMonth, setSelectedMonth] = useState(
    new Date(
      Number(
        rawFirstAvailableTime
          ? dayjs(rawFirstAvailableTime).format('YYYY')
          : dayjs().format('YYYY'),
      ),
      Number(
        rawFirstAvailableTime ? dayjs(rawFirstAvailableTime).format('M') : dayjs().format('M'),
      ) - 1,
      Number(
        rawFirstAvailableTime ? dayjs(rawFirstAvailableTime).format('D') : dayjs().format('D'),
      ),
    ).getMonth(),
  );
  const clickedInterval = (newTime, newIdx) => {
    setSelectedTime({ time: newTime, idx: newIdx });
  };

  useEffect(() => {
    dispatch(getDoctorTimeSlotsRequest({ id: doctor.id, specializationId, date: selectedDate }));
  }, []);

  useEffect(() => {
    setAvailableHours(doctorTimeSlotsState[selectedDate] ?? []);
  }, [doctorTimeSlotsState]);

  useEffect(() => {
    const newMonthSelected = new Date(selectedDate).getMonth();
    setSelectedTime({ time: '', idx: -1 });
    if (newMonthSelected !== selectedMonth) {
      setSelectedMonth(newMonthSelected);
      dispatch(getDoctorTimeSlotsRequest({ id: doctor.id, specializationId, date: selectedDate }));
    } else {
      setAvailableHours(doctorTimeSlotsState[selectedDate] ?? []);
    }
  }, [selectedDate]);

  useEffect(() => {
    setSelectedDate(dayjs(value).format('YYYY-MM-DD'));
  }, [value]);
  const currentStep = hasControl ? 3 : 2;
  const stepString = `${t('step')} ${currentStep}/${noOfSteps}`;
  const nextStep = () => {
    if (selectedTime?.idx === -1) {
      Alerts.simpleAlert(t('error'), t('noTimeSelected'));
      return;
    }
    if (!isLoggedIn) {
      Alerts.okCancelAlert(
        t('info'),
        t('alerts.youNeedToLogin'),
        () => {
          localStorage.setItem('CURRENT_LINK', `${window.location.pathname}`);
          navigate('/patient/login');
        },
        () => {},
      );
      navigate('patient/login');
    }
    requestAppointment('programmed', {
      forRequest: {
        timeStart: `${selectedDate} ${selectedTime.time}`,
        doctor: `${doctor?.id}`,
        specialization: `${specializationId}`,
        service: `${service || 'consultation'}`,
      },
      forUpdate: {
        date: selectedDate,
        doctor,
        specialization: `${specializationId}`,
        specializationId,
        startTime: `${selectedDate} ${selectedTime.time}`,
        time: selectedTime.time,
        scheduledAt: `${selectedDate} ${selectedTime.time}`,
        service,
        hasControl,
      },
    });
  };
  const abortAction = () => {
    navigate(-2);
  };

  return (
    <section className="appointment-steps">
      <div className="title-section">
        <div className="steps">
          <div className="icon-wrapper" onClick={() => navigate(-1)}>
            <CustomIcon
              className="custom-icon"
              color={Colors.darkGrey}
              size="24"
              icon="Left_arrow"
            />
          </div>
          {stepString}
          <div className="cancel-button">
            <button type="button" onClick={abortAction}>
              {t('abort')}
            </button>
          </div>
        </div>
        <h1>{t('appointment')}</h1>
      </div>
      <div className="medic-box">
        <div
          className="avatar"
          style={
            pictureMedia
              ? { backgroundImage: `url(${pictureMedia})` }
              : { backgroundColor: '#E5E7E8' }
          }
        >
          {!pictureMedia && (
            <div className="image-placeholder">{`${firstName?.charAt(0)}${lastName?.charAt(
              0,
            )}`}</div>
          )}
        </div>
        <div className="info">
          <div className="name">{fullNameWithTitle}</div>
          <div className="specialization">
            {doctor?.doctorSpecializations
              ?.filter((sp) => sp.parentId === specializationId)
              .map((sp) => `${sp.name} `)}
          </div>
        </div>
      </div>
      <div className="calendar-and-interval-hours">
        <div className="row">
          <div className="col-12">
            <div className="calendar">
              <div className="subtitle">{t('appointments.daysAvailable')}</div>
              <Calendar
                onChange={onChange}
                value={value}
                minDate={new Date()}
                onActiveStartDateChange={({ activeStartDate }) =>
                  dispatch(
                    getDoctorTimeSlotsRequest({
                      id: doctor.id,
                      specializationId,
                      date: dayjs(activeStartDate).format('YYYY-MM-DD'),
                    }),
                  )
                }
                tileDisabled={({ date }) =>
                  !(dayjs(date).format('YYYY-MM-DD') in doctorTimeSlotsState)
                }
                locale={t('calendar.locale')}
                defaultView="month"
                maxDetail="month"
                minDetail="month"
                nextLabel={
                  <CustomIcon
                    className="custom-icon"
                    color={Colors.patientColor}
                    size="24"
                    icon="Right_pointer"
                  />
                }
                prevLabel={
                  <CustomIcon
                    className="custom-icon rotate-180"
                    color={Colors.patientColor}
                    size="24"
                    icon="Right_pointer"
                  />
                }
                showNavigation
                showNeighboringMonth
              />
            </div>
          </div>
          <div className="col-12">
            <div className="subtitle">{t('appointments.hoursAvailable')}</div>
            <div className="hours-available-select">
              <ToggleButtonGroup
                type="radio"
                name="options"
                className="row rowp5 select-hour"
                style={{ width: '100%' }}
              >
                {availableHours.length > 0 ? (
                  availableHours.map((hour, index) => (
                    <ToggleButton
                      key={`${selectedDate}_${hour}`}
                      id={`tbg-radio-${index}`}
                      className="col-lg-4 col-xl-4 col-md-4 col-12 colp5"
                      value={`${selectedDate} ${hour}`}
                      onChange={(e) => {
                        setSelectedTime({ time: hour, idx: index });
                        const elemBtn = `tbg-radio-${index}`;
                        document
                          .getElementsByClassName(elemBtn)[0]
                          .scrollIntoView({ behavior: 'smooth' });
                      }}
                    >
                      <div className={`time tbg-radio-${index}`}>{hour}</div>
                    </ToggleButton>
                  ))
                ) : (
                  <div>{t('noTimeAvailable')}</div>
                )}
              </ToggleButtonGroup>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-btn">
        <button type="submit" className="orange-button" onClick={nextStep}>
          {t('continue')}
        </button>
      </div>
    </section>
  );
};

export default PatientAppointmentSetDate;
