import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'shared/styles/react-confirm-alert.css';
import { isDoctor } from 'shared/services/EnvService';
import Colors from 'shared/themes/Colors';
import callSpecialistWonIcon from 'shared/assets/icons/call-specialist-won-icon.svg';
import callSpecialistLostIcon from 'shared/assets/icons/call-specialist-lost-icon.svg';
import dayjs from 'dayjs';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share';
import * as DOMPurify from 'dompurify';
import CustomIcon from './CustomIcon';
import TimerComponent from './TimerComponent';

const simpleAlert = (title = '', body: any = '') => {
  const buttonClass = isDoctor() ? 'doctor-button' : 'patient-button';
  return confirmAlert({
    closeOnClickOutside: false,
    closeOnEscape: false,
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal">
          <div className="modal-header">
            <div className="modal-title">{title}</div>
          </div>
          <div className="modal-body">
            <p>{body}</p>

            <div className="actions">
              <button className={buttonClass} type="button" onClick={onClose}>
                Ok
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const errorAlert = (body: any = '') => {
  const buttonClass = isDoctor() ? 'doctor-button' : 'patient-button';
  return confirmAlert({
    closeOnClickOutside: false,
    closeOnEscape: false,
    onkeyPress: () => {
      // @ts-ignore
      document.getElementById('iGotIt').focus();
    },
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal">
          <div className="modal-body">
            <div className="text-center mb-20">
              <CustomIcon
                className="custom-icon m-auto"
                color={Colors.mediumGrey}
                size="40"
                icon="Warning_2"
              />
            </div>
            <p>{body}</p>

            <div className="actions">
              <button id="iGotIt" className={buttonClass} type="button" onClick={onClose}>
                {`${i18next.t('iUnderstand')}`}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const okCancelAlert = (title = '', body = '', okayAction = () => {}, cancelAction = () => {}) => {
  const buttonClass = isDoctor() ? 'doctor-button' : 'patient-button';
  return confirmAlert({
    closeOnClickOutside: false,
    closeOnEscape: false,
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal">
          <div className="modal-header">
            <div className="modal-title">{title}</div>
          </div>
          <div className="modal-body">
            <p>{body}</p>

            <div className="actions">
              <button
                className="cancel-button"
                type="button"
                onClick={() => {
                  cancelAction();
                  onClose();
                }}
              >
                {`${i18next.t('cancel')}`}
              </button>
              <button
                className={buttonClass}
                type="button"
                onClick={() => {
                  okayAction();
                  onClose();
                }}
              >
                {`${i18next.t('ok')}`}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const actionAlert = (title = '', body = '', buttonText = 'Ok', action = () => {}) => {
  const buttonClass = isDoctor() ? 'doctor-button' : 'patient-button';
  return confirmAlert({
    closeOnClickOutside: false,
    closeOnEscape: false,
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal">
          <div className="modal-header">
            <div className="modal-title">{title}</div>
          </div>
          <div className="modal-body">
            <p>{body}</p>

            <div className="actions">
              <button
                className={buttonClass}
                type="button"
                onClick={() => {
                  action();
                  onClose();
                }}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const twoActionsAlert = (
  title = '',
  body = '',
  firstButtonText = '',
  firstButtonAction = () => {},
  secondButtonText = '',
  secondButtonAction = () => {},
) => {
  const buttonClass = isDoctor() ? 'doctor-button' : 'patient-button';
  return confirmAlert({
    closeOnClickOutside: false,
    closeOnEscape: false,
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal">
          {title.length > 0 && (
            <div className="modal-header">
              <div className="modal-title">{title}</div>
            </div>
          )}
          <div className="modal-body">
            <p>{body}</p>

            <div className="actions">
              <button
                className="cancel-button"
                type="button"
                onClick={() => {
                  firstButtonAction();
                  onClose();
                }}
              >
                {firstButtonText}
              </button>
              <button
                className={buttonClass}
                type="button"
                onClick={() => {
                  secondButtonAction();
                  onClose();
                }}
              >
                {secondButtonText}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const threeActionsAlert = (
  title = '',
  body = '',
  firstButtonText = '',
  firstButtonAction = () => {},
  secondButtonText = '',
  secondButtonAction = () => {},
  thirdButtonText = '',
  thirdButtonAction = () => {},
) => {
  const buttonClass = isDoctor() ? 'doctor-button' : 'patient-button';
  return confirmAlert({
    closeOnClickOutside: false,
    closeOnEscape: false,
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal">
          <div className="modal-header">
            <div className="modal-title">{title}</div>
          </div>
          <div className="modal-body">
            <p>{body}</p>

            <div className="actions">
              <button
                className={buttonClass}
                type="button"
                onClick={() => {
                  firstButtonAction();
                  onClose();
                }}
              >
                {firstButtonText}
              </button>
              <button
                className={buttonClass}
                type="button"
                onClick={() => {
                  secondButtonAction();
                  onClose();
                }}
              >
                {secondButtonText}
              </button>
              <button
                className="cancel-button"
                type="button"
                onClick={() => {
                  thirdButtonAction();
                  onClose();
                }}
              >
                {thirdButtonText}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const doctorIncomingCallAlert = (
  title = '',
  imgUrl = '',
  firstName = '',
  lastName = '',
  fullName = '',
  subtitle = '',
  okayAction = () => {},
  cancelAction = () => {},
) => {
  const buttonClass = isDoctor() ? 'doctor-button' : 'patient-button';
  return confirmAlert({
    closeOnClickOutside: false,
    closeOnEscape: false,
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal">
          <div className="modal-header">
            <div className="modal-title">{title}</div>
          </div>
          <div className="modal-body">
            <div className="medic-box">
              <div
                className="avatar"
                style={
                  imgUrl ? { backgroundImage: `url(${imgUrl})` } : { backgroundColor: '#E5E7E8' }
                }
              >
                {!imgUrl && (
                  <div className="image-placeholder">{`${firstName?.charAt(0)}${lastName?.charAt(
                    0,
                  )}`}</div>
                )}
              </div>
              <div className="fullName">{fullName}</div>
              <div className="specialization">{subtitle}</div>
            </div>

            <div className="actions">
              <button
                className="cancel-button"
                type="button"
                onClick={() => {
                  cancelAction();
                  onClose();
                }}
              >
                {`${i18next.t('decline')}`}
              </button>
              <button
                className={buttonClass}
                type="button"
                onClick={() => {
                  okayAction();
                  onClose();
                }}
              >
                <CustomIcon className="custom-icon" color={Colors.white} size="24" icon="Video" />
                {`${i18next.t('takeCall')}`}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const doctorIncomingCallSpecialistAlert = (
  title = '',
  priceAsString = '',
  auctionFinishTime = '',
  timeOptions: number[] = [],
  okayAction = (timeEstimation: any) => {},
  cancelAction = () => {},
) => {
  const buttonClass = isDoctor() ? 'doctor-button' : 'patient-button';
  const timerClass = isDoctor() ? 'doctor-timer' : 'patient-timer';

  return confirmAlert({
    customUI: ({ onClose }) => {
      const changeMinutesTranslation = (option) => {
        if (+option === 1) {
          // @ts-ignore
          document.getElementById('bid-minutes-singular').style.display = 'block';
          // @ts-ignore
          document.getElementById('bid-minutes-plural').style.display = 'none';
        } else {
          // @ts-ignore
          document.getElementById('bid-minutes-singular').style.display = 'none';
          // @ts-ignore
          document.getElementById('bid-minutes-plural').style.display = 'block';
        }
      };

      return (
        <div className="react-confirm-alert-modal">
          <div className="modal-header">
            <div className="modal-title">{title}</div>
            <div className={`timer ${timerClass}`}>
              <TimerComponent
                action={() => {}}
                endTime={auctionFinishTime}
                onTimeAboutToEnd={() => {}}
              />
              <p>&nbsp;{`${i18next.t('min')}`}</p>
            </div>
          </div>
          <div className="modal-body">
            <p className="alert-text">{`${i18next.t('quickCallText')}`}</p>

            <div className="info-message dark justify-between">
              <div className="subtitle">{`${i18next.t('quickCallPriceLabel')}`}</div>
              <div className="price">{priceAsString}</div>
            </div>

            <div className="info-message dark">
              <CustomIcon
                className="custom-icon"
                color={Colors.mediumGrey}
                size="24"
                icon="Warning_2"
              />
              {`${i18next.t('quickCallInfoMessage')}`}
            </div>

            <div className="subtitle">Alegeți timp preluare pacient</div>

            <div className="minutes">
              <div className="form-select-label">
                <select
                  required
                  id="call-specialist-alert-minutes"
                  name="min"
                  className="custom-select"
                  onChange={(e) => changeMinutesTranslation(e.target.value)}
                >
                  {timeOptions.map((option) => (
                    <option key={option}>{option}</option>
                  ))}
                </select>
              </div>
              <div
                id="bid-minutes-singular"
                className="min"
                style={{ display: timeOptions.indexOf(1) > -1 ? 'block' : 'none' }}
              >
                {`${i18next.t('minute')}`}
              </div>
              <div
                id="bid-minutes-plural"
                className="min"
                style={{ display: timeOptions.indexOf(1) > -1 ? 'none' : 'block' }}
              >
                {`${i18next.t('minutes')}`}
              </div>
            </div>

            <div className="actions">
              <button
                className="cancel-button"
                type="button"
                onClick={() => {
                  cancelAction();
                  onClose();
                }}
              >
                {`${i18next.t('decline')}`}
              </button>
              <button
                className={buttonClass}
                type="button"
                onClick={() => {
                  // @ts-ignore
                  okayAction(+document.getElementById('call-specialist-alert-minutes').value);
                  onClose();
                }}
              >
                {`${i18next.t('sendCallNowMins')}`}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const doctorWonCallSpecialistAlert = (
  startTime = '',
  fullName = '',
  firstName = '',
  lastName = '',
  subtitle = '',
  okayAction = () => {},
) => {
  const buttonClass = isDoctor() ? 'doctor-button' : 'patient-button';
  return confirmAlert({
    closeOnClickOutside: false,
    closeOnEscape: false,
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal">
          <div className="modal-header">
            <div className="modal-title">{`${i18next.t('auctionWon')}`}</div>
          </div>
          <div className="modal-body">
            <div className="medic-box">
              <div
                className="avatar"
                style={{ backgroundImage: `url(${callSpecialistWonIcon})`, border: 'none' }}
              />
              <div className="fullName">{fullName}</div>
              <div className="specialization">{subtitle}</div>
            </div>

            <div className="message">
              {`${i18next.t('auctionWonText', {
                time: dayjs(startTime).format('HH:mm'),
              })}`}
            </div>

            <div className="actions">
              <button
                className={buttonClass}
                type="button"
                onClick={() => {
                  okayAction();
                  onClose();
                }}
              >
                {`${i18next.t('close')}`}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const doctorLostCallSpecialistAlert = (cancelAction = () => {}) => {
  const buttonClass = isDoctor() ? 'doctor-button' : 'patient-button';
  return confirmAlert({
    closeOnClickOutside: false,
    closeOnEscape: false,
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal">
          <div className="modal-header">
            <div className="modal-title">{`${i18next.t('auctionLost')}`}</div>
          </div>
          <div className="modal-body">
            <div className="medic-box">
              <div
                className="avatar"
                style={{ backgroundImage: `url(${callSpecialistLostIcon})`, border: 'none' }}
              />
            </div>

            <div className="message">{`${i18next.t('auctionLostText')}`}</div>

            <div className="actions">
              <button
                className={buttonClass}
                type="button"
                onClick={() => {
                  cancelAction();
                  onClose();
                }}
              >
                {`${i18next.t('close')}`}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const answerCallAlert = (
  title = '',
  imgUrl = '',
  fullName = '',
  firstName = '',
  lastName = '',
  specialization = '',
  action = () => {},
) => {
  const buttonClass = isDoctor() ? 'doctor-button' : 'patient-button';
  return confirmAlert({
    closeOnClickOutside: false,
    closeOnEscape: false,
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal">
          <div className="modal-header">
            <div className="modal-title">{title}</div>
          </div>
          <div className="modal-body">
            <div className="medic-box">
              <div
                className="avatar"
                style={
                  imgUrl ? { backgroundImage: `url(${imgUrl})` } : { backgroundColor: '#E5E7E8' }
                }
              >
                {!imgUrl && (
                  <div className="image-placeholder">{`${firstName?.charAt(0)}${lastName?.charAt(
                    0,
                  )}`}</div>
                )}
              </div>
              <div className="fullName">{fullName}</div>
              <div className="specialization">{specialization}</div>
            </div>

            <div className="actions">
              <button
                className={buttonClass}
                type="button"
                onClick={() => {
                  action();
                  onClose();
                }}
              >
                <CustomIcon className="custom-icon" color={Colors.white} size="24" icon="Video" />
                {`${i18next.t('appointments.enterAppointment')}`}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const callSpecialistDoctorNotFoundAlert = (
  patientFirstName = '',
  id = '',
  imgUrl = '',
  fullName = '',
  firstName = '',
  lastName = '',
  specialization = '',
  firstAvailableAt = '',
  priceAndDuration = '',
  action = () => {},
) => {
  const sanitizer = DOMPurify.sanitize;
  const buttonClass = isDoctor() ? 'doctor-button' : 'patient-button';
  return confirmAlert({
    closeOnClickOutside: true,
    closeOnEscape: true,
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal">
          <div className="modal-header">
            <div className="modal-title">{`${i18next.t('callSpecialistDoctorSearchFailed')}`}</div>
            <button onClick={() => onClose()} type="button" className="alert-close">{`${i18next.t(
              'close2',
            )}`}</button>
          </div>
          <div className="modal-body">
            <p>{`${i18next.t('callSpecialistDoctorSearchFailedText1')}`}</p>
            <p
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  i18next.t('callSpecialistDoctorSearchFailedText2', {
                    specialization: `<span class="trans-placeholder text-capitalize">${specialization}</span>`,
                  }),
                ),
              }}
            />

            <div className="medic-box-alt-wrapper">
              <div className="medic-box-alt">
                <div className="info">
                  <div className="profile-picture">
                    <div
                      className="avatar"
                      style={
                        imgUrl
                          ? { backgroundImage: `url(${imgUrl})` }
                          : { backgroundColor: '#E5E7E8' }
                      }
                    >
                      {!imgUrl && (
                        <div className="image-placeholder">{`${firstName?.charAt(
                          0,
                        )}${lastName?.charAt(0)}`}</div>
                      )}
                    </div>
                  </div>
                  <div className="details">
                    <div className="name">Dr. {fullName}</div>
                    <div className="specialization">{specialization}</div>
                  </div>
                </div>
              </div>

              <div className="footer-box">
                <div className="d-flex justify-content-between">
                  <div className="subtitle">{`${i18next.t('medicalApp.availability')}`}</div>
                  <div className="availability">{firstAvailableAt}</div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="subtitle">{`${i18next.t('appointments.appointment')}`}</div>
                  <div className="price">
                    {priceAndDuration}&nbsp;{`${i18next.t('minutes')}`}
                  </div>
                </div>
              </div>
            </div>

            <div className="actions">
              <button
                className={buttonClass}
                type="button"
                onClick={() => {
                  action();
                  onClose();
                }}
              >
                <CustomIcon
                  className="custom-icon"
                  color={Colors.white}
                  size="24"
                  icon="Add_programare"
                />
                {`${i18next.t('medicalApp.homeScreenPatient.schedule')}`}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};

const shareAlert = (title = '', body: any = '', invitationCode: any = '') => {
  const buttonClass = isDoctor() ? 'doctor-button' : 'patient-button';
  const invitationText = `${body.body1} ${body.body2}`;
  const url = `http://bit.ly/ringdocpacienti`;
  const iconsSize = 48;
  const invitationTitle = `${i18next.t('recommendApp')}`;
  const emailUrl = `${i18next.t('sharePersonalCodeTextWeb', {
    personalCode: invitationCode,
  })} ${url}`;

  return confirmAlert({
    closeOnClickOutside: true,
    closeOnEscape: true,
    customUI: ({ onClose }) => {
      return (
        <div className="react-confirm-alert-modal">
          <div className="modal-header">
            <div className="modal-title">{title}</div>
          </div>
          <div className="modal-body">
            <p>{body.body1}</p>
            <p>{body.body2}</p>
          </div>
          <div className="modal-footer shareAlertModal">
            <div className="shareModalFooterTitle">{`${i18next.t('share')}`}</div>
            <div className="social-list">
              <FacebookShareButton url={url} onShareWindowClose={onClose}>
                <FacebookIcon size={iconsSize} />
              </FacebookShareButton>
              <TwitterShareButton title={invitationText} url={url} onShareWindowClose={onClose}>
                <XIcon size={iconsSize} round />
              </TwitterShareButton>
              <FacebookMessengerShareButton
                title={invitationText}
                redirectUri={url}
                url={url}
                onShareWindowClose={onClose}
                appId="380344953549489"
              >
                <FacebookMessengerIcon size={iconsSize} />
              </FacebookMessengerShareButton>
              <LinkedinShareButton title={invitationText} url={url} onShareWindowClose={onClose}>
                <LinkedinIcon size={iconsSize} />
              </LinkedinShareButton>
              <ViberShareButton title={invitationText} url={url} onShareWindowClose={onClose}>
                <ViberIcon size={iconsSize} />
              </ViberShareButton>
              <TelegramShareButton title={invitationText} url={url} onShareWindowClose={onClose}>
                <TelegramIcon size={iconsSize} />
              </TelegramShareButton>
              <WhatsappShareButton title={invitationText} url={url} onShareWindowClose={onClose}>
                <WhatsappIcon size={iconsSize} />
              </WhatsappShareButton>
              <EmailShareButton
                subject={invitationTitle}
                url={emailUrl}
                onShareWindowClose={onClose}
              >
                <EmailIcon size={iconsSize} />
              </EmailShareButton>
            </div>
          </div>
        </div>
      );
    },
  });
};

export default {
  simpleAlert,
  errorAlert,
  okCancelAlert,
  actionAlert,
  twoActionsAlert,
  threeActionsAlert,
  doctorIncomingCallAlert,
  doctorIncomingCallSpecialistAlert,
  doctorWonCallSpecialistAlert,
  doctorLostCallSpecialistAlert,
  answerCallAlert,
  callSpecialistDoctorNotFoundAlert,
  shareAlert,
};
