export const GET_ABOUT_US_REQUEST = 'GET_ABOUT_US_REQUEST';
export const GET_ABOUT_US_SUCCESS = 'GET_ABOUT_US_SUCCESS';
export const GET_ABOUT_US_ERROR = 'GET_ABOUT_US_ERROR';

export const GET_PRIVACY_POLICY_REQUEST = 'GET_PRIVACY_POLICY_REQUEST';
export const GET_PRIVACY_POLICY_SUCCESS = 'GET_PRIVACY_POLICY_SUCCESS';
export const GET_PRIVACY_POLICY_ERROR = 'GET_PRIVACY_POLICY_ERROR';

export const GET_TERMS_REQUEST = 'GET_TERMS_REQUEST';
export const GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS';
export const GET_TERMS_ERROR = 'GET_TERMS_ERROR';

export const GET_TWILIO_TERMS_REQUEST = 'GET_TWILIO_TERMS_REQUEST';
export const GET_TWILIO_TERMS_SUCCESS = 'GET_TWILIO_TERMS_SUCCESS';
export const GET_TWILIO_TERMS_ERROR = 'GET_TWILIO_TERMS_ERROR';

export const GET_STRIPE_TERMS_REQUEST = 'GET_STRIPE_TERMS_REQUEST';
export const GET_STRIPE_TERMS_SUCCESS = 'GET_STRIPE_TERMS_SUCCESS';
export const GET_STRIPE_TERMS_ERROR = 'GET_STRIPE_TERMS_ERROR';

export const GET_GDPR_REQUEST = 'GET_GDPR_REQUEST';
export const GET_GDPR_SUCCESS = 'GET_GDPR_SUCCESS';
export const GET_GDPR_ERROR = 'GET_GDPR_ERROR';

export const GET_CAMPAIGN_RULES_REQUEST = 'GET_CAMPAIGN_RULES_REQUEST';
export const GET_CAMPAIGN_RULES_SUCCESS = 'GET_CAMPAIGN_RULES_SUCCESS';
export const GET_CAMPAIGN_RULES_ERROR = 'GET_CAMPAIGN_RULES_ERROR';
