import 'shared/styles/static-pages.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getCampaignRulesState } from 'shared/redux/src/StatesGetter';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { getCampaignRulesRequest } from 'shared/redux/actions/StaticPagesActions';
import FooterSection from 'shared/components/FooterSection';
import HeaderMenu from '../../components/HeaderMenu';

const SettingsCampaignRules = () => {
  const { t } = useTranslation();
  const devRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { name, content } = useSelector(getCampaignRulesState, shallowEqual);

  useEffect(() => {
    dispatch(getCampaignRulesRequest({}));
    if (devRef.current) devRef.current.innerHTML = content;
  }, [content]);

  useEffect(() => {
    dispatch(getCampaignRulesRequest({}));
    if (devRef.current) devRef.current.innerHTML = content;

    const anchor = window.location.hash.slice(1);
    setTimeout(() => {
      if (anchor) {
        const anchorEl = document.getElementById(anchor);
        if (anchorEl) {
          anchorEl.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 1000);
  }, []);

  return (
    <div className="privacy-page">
      <Helmet>
        <title>{t('contactPage.titlePage')}</title>
        <meta
          name="description"
          content="Completeaza formularul si cere detalii despre Ringdoc si modul de functionare al aplicatiei chiar acum"
        />
      </Helmet>

      <div className="container-fluid">
        <div className="row">
          <HeaderMenu />

          <main className="container">
            <section className="static-pages">
              {/* <div className="title-section"> */}
              {/*  <h1>{name}</h1> */}
              {/* </div> */}

              <div ref={devRef} />
            </section>
          </main>

          <FooterSection />
        </div>
      </div>
    </div>
  );
};

export default SettingsCampaignRules;
