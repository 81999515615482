export const getAuthState = (state) => state.authState;
export const getSharedState = (state) => state.sharedState;
export const getUserProfileState = (state) => state.userProfileState;
export const getMyReviewsState = (state) => state.myReviewsState;
export const getDoctorPastAppointmentsState = (state) => state.doctorPastAppointmentsState;
export const getDoctorUpcomingAppointmentsState = (state) => state.doctorUpcomingAppointmentsState;
export const getDoctorTodayAppointmentsState = (state) => state.doctorTodayAppointmentsState;
export const getDoctorAppointmentDetailsState = (state) => state.doctorAppointmentDetailsState;
export const getTwilioTokenState = (state) => state.twilioTokenState;
export const getCountriesState = (state) => state.countriesState;
export const getLanguagesState = (state) => state.languagesState;
export const getPushesState = (state) => state.pushesState;
export const getLocalSpecializationState = (state) => state.localSpecializationState;
export const getDoctorTimetableState = (state) => state.doctorTimetableState;
export const getMySpecializationsState = (state) => state.mySpecializationsState;
export const getSpecializationsState = (state) => state.specializationsState;
export const getSpecializationsHomeState = (state) => state.specializationsHomeState;
export const getDoctorAppointmentState = (state) => state.doctorAppointmentState;
export const getPrescriptionState = (state) => state.prescriptionState;
export const getDoctorCallsStackState = (state) => state.doctorCallsStackState;
export const getBalanceState = (state) => state.balanceState;
export const getPayoutsState = (state) => state.payoutsState;
export const getPayoutTransactionsState = (state) => state.payoutTransactionsState;
export const getIncomeState = (state) => state.incomeState;
export const getIncomeTransactionsState = (state) => state.incomeTransactionsState;
export const getIncomeTransactionsMonthlyState = (state) => state.incomeTransactionsMonthlyState;
export const getNotificationsSettingsState = (state) => state.notificationsSettingsState;
export const getPatientNotificationsSettingsState = (state) =>
  state.patientNotificationsSettingsState;
export const getSharedAppointmentState = (state) => state.sharedAppointmentState;
export const getErrorState = (state) => state.errorState;
export const getAppointmentSettingsState = (state) => state.appointmentSettingsState;
export const getCurrencyState = (state) => state.currencyState;
export const getAboutUsState = (state) => state.aboutUsState;
export const getPrivacyPolicyState = (state) => state.privacyPolicyState;
export const getTermsState = (state) => state.termsState;
export const getGDPRState = (state) => state.GDPRState;
export const getCampaignRulesState = (state) => state.CampaignRulesState;
export const getStripeTermsState = (state) => state.stripeTermsState;
export const getTwilioTermsState = (state) => state.twilioTermsState;

export const getPatientProfileState = (state) => state.patientProfileState;
export const getDoctorProfileState = (state) => state.doctorProfileState;
export const getDoctorReviewsState = (state) => state.doctorReviewsState;
export const getAvailableDoctorsState = (state) => state.availableDoctorsState;
export const getFavouriteDoctorsState = (state) => state.favouriteDoctorsState;
export const getSpecializationDoctorsState = (state) => state.specializationDoctorsState;
export const getSortingOptionsState = (state) => state.sortingOptionsState;
export const getFamilyMembersState = (state) => state.familyMembersState;
export const getPossibleKinshipState = (state) => state.possibleKinshipState;
export const getLocalCurrentPatientProfileState = (state) => state.localCurrentPatientProfileState;
export const getPaymentState = (state) => state.paymentState;
export const getPatientAppointmentState = (state) => state.patientAppointmentState;
export const getPatientPastAppointmentsState = (state) => state.patientPastAppointmentsState;
export const getPatientUpcomingAppointmentsState = (state) =>
  state.patientUpcomingAppointmentsState;
export const getPatientLocalChooseSpecializationState = (state) =>
  state.patientLocalChooseSpecializationState;
export const getPatientAppointmentDetailsState = (state) => state.patientAppointmentDetailsState;
export const getDoctorTimeSlotsState = (state) => state.doctorTimeSlotsState;
export const getPaymentMethodsState = (state) => state.paymentMethodsState;
export const getPatientTransactionsState = (state) => state.patientTransactionsState;
export const getMedicalRecordCategoryState = (state) => state.medicalRecordCategoryState;
export const getPatientMedicalRecordsState = (state) => state.patientMedicalRecordsState;
export const getParticipantPlatformState = (state) => state.participantPlatformState;
export const getRemoteConfigState = (state) => state.remoteConfigState;
export const getVouchersState = (state) => state.vouchersState;
export const getValidVouchersState = (state) => state.validVouchersState;
export const getRecommendationsState = (state) => state.recommendationsState;
export const getLocalChooseCardState = (state) => state.localChooseCardState;
export const getLocalChooseVoucherState = (state) => state.localChooseVoucherState;
export const getLocalCityState = (state) => state.localCityState;
export const getLocalDateState = (state) => state.localDateState;
export const getDoctorPastTimetableState = (state) => state.doctorPastTimetableState;
export const getTitlesState = (state) => state.titlesState;
export const getDoctorExceptionTimetableState = (state) => state.doctorExceptionTimetableState;
export const getDoctorRecurrenceTimetableState = (state) => state.doctorRecurrenceTimetableState;
export const referralState = (state) => state.referralReducer;
export const getTranslationsState = (state) => state.translationsState;
export const getMlmBonusesState = (state) => state.mlmBonusesState;
export const getMlmAcceptedInvitationsState = (state) => state.mlmAcceptedInvitationsState;
export const getMlmSentInvitationsState = (state) => state.mlmSentInvitationsState;
export const getAllDoctorsMlmState = (state) => state.allDoctorsMlmState;
export const getAllReviewsState = (state) => state.allReviewsState;
export const getPendingAppointmentState = (state) => state.pendingAppointmentState;
export const settingsState = (state) => state.settingsState;
export const servicesState = (state) => state.serviceState;

export const getVideoCallGetTokenState = (state) => state.VideoCallTokenState;
