import _ from 'lodash';
import i18next from 'i18next';
import GCStorageService from 'shared/services/GCStorageService';
import visaCardImage from 'shared/assets/icons/cards-logo/visa-card-small-icon.svg';
import masterCardImage from 'shared/assets/icons/cards-logo/mastercard-card-small-icon.svg';
import dayjs from 'dayjs';
import clip from 'text-clipper';
import StorageService from '../services/StorageService';

const getCurrencyIdByCode = (_r, ref, value, key) => {
  if (!Array.isArray(_r) || _r.length === 0) return '';
  try {
    if (!value) {
      return _r[0][key];
    }
    return _r.find((r) => r[ref] === value)[key];
  } catch {
    return _r[0][key];
  }
};

const capitalizeFirstLetter = (str: string | null | undefined) => {
  if (str === null || str === undefined) {
    return '';
  }
  return str?.length ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};

const convertAmount = (amount: string | number) => {
  if (!amount) {
    return 0;
  }
  if (typeof amount === 'string') {
    return parseInt(amount, 10)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
  return amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const getObjectKeyByNestedValue = (object, subKey, value) => {
  return Object.keys(object).find((key) => object[key][subKey] === value);
};

const makeSelectOptions = (dataArray, label, value) => {
  if (!Array.isArray(dataArray) || !dataArray.length) {
    return [{ label: '', value: '' }];
  }
  return dataArray.map((row) => {
    return {
      label: row[label],
      value: row[value],
    };
  });
};

const makeSelectOptions2 = (dataArray, label, value, name2) => {
  if (!Array.isArray(dataArray) || !dataArray.length) {
    return [{ label: '', value: '', name2: '' }];
  }
  return dataArray.map((row) => {
    return {
      label: row[label],
      value: row[value],
      name2: row[name2],
    };
  });
};

const sortObjectByDateKey = (param: Record<string, string>[], key: string | number) =>
  param.sort((a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime());

const sortObjectByKey = (param: Record<string, number>[], key: string | number) => {
  if (param && Array.isArray(param) && param.length) {
    return param.sort((a, b) => a[key] - b[key]);
  }
  return [];
};

const rejoinableAppointment = (status, dateStart, duration) => {
  const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
  const dateEnd = dayjs(dateStart).add(duration, 'minute').format('YYYY-MM-DD HH:mm:ss');
  return (
    status === 'in_progress' ||
    (status === 'ready_to_start' &&
      dayjs(now).valueOf() >= dayjs(dateStart).valueOf() &&
      dayjs(now).valueOf() < dayjs(dateEnd).valueOf())
  );
};

const maybeLongText = (value, limit, separator = '') => {
  return _.truncate(value, {
    length: limit,
    separator,
  });
};

const timeUntil = (date) => {
  const now = dayjs();
  const then = dayjs(date);
  const diff = then.diff(now, 'second');

  const days = Math.floor(diff / 86400);
  const hours = Math.floor(diff / 3600);
  const minutes = Math.floor(diff / 60);
  const seconds = diff % 60;

  return { days, hours, minutes, seconds: Math.max(seconds, 0) };
};

const durationBetweenDates = (end, status) => {
  const { days, hours, minutes, seconds } = timeUntil(end);
  let untilHours = hours;
  if (days) {
    untilHours = days * 24 + hours;
  }
  if (untilHours < 0) {
    return status;
  }
  if (untilHours) {
    return `${`0${untilHours}`.slice(-2)}:${`0${minutes}`.slice(-2)}:${`0${seconds}`.slice(-2)}`;
  }
  return `00:${`0${minutes}`.slice(-2)}:${`0${seconds}`.slice(-2)}`;
};

const solveImageLogoByType = (type) => {
  switch (type) {
    case 'visa':
      return visaCardImage;
    case 'mastercard':
      return masterCardImage;
    default:
      return null;
  }
};
const formatExpirationCard = (event) => {
  const inputChar = String.fromCharCode(event.keyCode);
  const code = event.keyCode;
  const allowedKeys = [8];
  if (allowedKeys.indexOf(code) !== -1) {
    return;
  }

  // eslint-disable-next-line no-param-reassign
  event.target.value = event.target.value
    .replace(
      /^([1-9]\/|[2-9])$/g,
      '0$1/', // 3 > 03/
    )
    .replace(
      /^(0[1-9]|1[0-2])$/g,
      '$1/', // 11 > 11/
    )
    .replace(
      /^([0-1])([3-9])$/g,
      '0$1/$2', // 13 > 01/3
    )
    .replace(
      /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
      '$1/$2', // 141 > 01/41
    )
    .replace(
      /^([0]+)\/|[0]+$/g,
      '0', // 0/ > 0 and 00 > 0
    )
    .replace(
      /[^\d/]|^[/]*$/g,
      '', // To allow only digits and `/`
    )
    .replace(
      /\/\//g,
      '/', // Prevent entering more than 1 `/`
    );
};

const parseToInt = (param: string | number) => {
  if (!param) {
    return 0;
  }
  if (param.constructor === String) {
    return parseInt(param, 10);
  }
  return param;
};

/**
 * @param dataArray
 * @param labelKey
 * @returns {*[]}
 */
const makeSectionListFormat = (dataArray, labelKey) => {
  if (!Array.isArray(dataArray) || !dataArray.length) {
    return [];
  }
  const key = (row) => row[labelKey];
  const groupToKey = (dataGroup, groupKey) => ({
    key: groupKey,
    data: dataGroup,
  });
  return _.chain(dataArray).groupBy(key).map(groupToKey).value();
};

const convertIsoDate = (date) => {
  // return date;
  return `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;
};

const makeRandomIdentifier = (length: number) => {
  const result: string[] = [];
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
};

const stringFormat = (phrase: string, ...args: string[]) =>
  phrase?.split('%s%').reduce((aggregate, chunk, i) => aggregate + chunk + (args[i] || ''), '');

const arrayToObject = (arr: Record<string, string>[]) => {
  const obj = {};
  if (Array.isArray(arr) && arr.length) {
    arr.map((row) => {
      // eslint-disable-next-line prefer-destructuring
      obj[row[0]] = row[1] ?? '';
      return true;
    });
  }
  return obj;
};

const randomColor = () =>
  `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')}`;

const roundToTwo = (num: number) => +`${Math.round(Number(`${num}e+2`))}e-2`;

const toFixedTwo = (num: number) => {
  const rounded = +`${Math.round(Number(`${num}e+2`))}e-2`;
  if (rounded) {
    return rounded.toString();
  }
  return '0.00';
};

const calculateAge = (dateString: string): number => {
  const today = new Date();
  const validDate = dateString?.length ? dateString : dayjs().format('YYYY-MM-DD HH:mm:ss');
  const birthDate = dayjs(validDate);
  let age = today.getFullYear() - parseInt(birthDate.format('YYYY'), 10);
  const m = today.getMonth() - parseInt(birthDate.format('MM'), 10);
  if (m < 0 || (m === 0 && today.getDate() < birthDate.date())) {
    age -= 1;
  }
  return age;
};

const shortenText = (text, char) => {
  if (text === null || text === undefined) {
    return '';
  }

  const htmlClipOptions = {
    html: true,
    stripTags: ['img', 'svg', 'iframe'],
    maxLines: 3,
    indicator: '...',
  };

  return clip(text, char, htmlClipOptions);
};

const getMedicalFilesByKey = (data, key, secondKey) => {
  if (!data || !data.length) {
    return {};
  }
  const sortedData = sortObjectByDateKey(data, secondKey);
  const files = {};
  sortedData.map((row) => {
    files[row.createdAtFormatted] = files[row.createdAtFormatted] ?? [];
    files[row.createdAtFormatted].push(row);
    return true;
  });
  const months = {};
  Object.keys(files).map((month) => {
    months[month] = makeSectionListFormat(files[month], key);
    return true;
  });
  return months;
};

const dateFormat = 'yyyy-mm-dd';

const getDaysBetween = (start, end) => {
  const startFormat = dayjs(start).format('YYYY-MM-DD');
  const startObject = new Date(startFormat);
  const endFormat = dayjs(end).format('YYYY-MM-DD');
  const endObject = new Date(endFormat);
  const difference = startObject.getTime() - endObject.getTime();
  return Math.ceil(difference / (1000 * 3600 * 24));
};

const getDaysHoursMinutesBetweenDates = (timeStr, status) => {
  const validTime = timeStr ?? new Date();
  const { days, hours, minutes } = timeUntil(validTime);
  if (days > 0) {
    let goodDays = days;
    if (hours > 10) {
      goodDays += 1;
    }
    return `${i18next.t('in')} ${goodDays} ${i18next.t('days')}`;
  }
  if (hours > 0 && days >= 0) {
    return `${i18next.t('in')} ${hours} ${i18next.t('hours')}`;
  }
  if (hours < 0 || days < 0) {
    return status;
  }
  if (minutes <= 0) {
    return status;
  }
  return `${i18next.t('in')} ${minutes} ${i18next.t('minutes')}`;
};

const getCurrentWeekDates = () => {
  const curr = new Date();
  const week: string[] = [];
  for (let i = 1; i <= 7; i += 1) {
    const first = curr.getDate() - curr.getDay() + i;
    const day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    week.push(day);
  }
  return week;
};

const zeroPad = (num) => {
  if (num >= 0 && num <= 9) return `0${num}`;
  return num.toString();
};

const addDays = (date, daysToAdd) => {
  return date.setDate(date.getDate() + daysToAdd);
};

// programmed, callNow, emergency freeTalk
const appointmentTypeMapper = (appointmentType) => {
  switch (appointmentType) {
    case 'emergency':
      return 'emergency';
    case 'call_now':
      return 'callNow';
    case 'free_talk':
      return 'freeTalk';
    case 'call_specialist':
      return 'callSpecialist';
    default:
      return 'programmed';
  }
};

const getSpecializationPrices = (specializations) => {
  const prices = {};
  specializations.map((specialization) => {
    const speccializationPricelist = {};
    specialization.doctorServices.map((service) => {
      const { price, id } = service;
      speccializationPricelist[id] = `${parseInt(
        price.toString().substring(0, price.toString().length - 2),
        10,
      )}`;
      return true;
    });
    prices[specialization.doctorSpecializationId] = speccializationPricelist;
    return true;
  });
  return prices;
};

const getAddSpecializationPrices = (services) => {
  const pricesValues = {};
  services.map((service) => {
    const { slug } = service;
    pricesValues[slug] = '';
    return true;
  });
  return pricesValues;
};

const getSpecializationDuration = (specializations) => {
  const durations = {};
  specializations.map((specialization) => {
    const speccializationDureationlist = {};
    specialization.doctorServices.map((service) => {
      const { duration, id } = service;
      speccializationDureationlist[id] = duration ? `${duration}` : '-';
      return true;
    });
    durations[specialization.doctorSpecializationId] = speccializationDureationlist;
    return true;
  });
  return durations;
};

const getAddSpecializationDuration = (services) => {
  const durationValues = {};
  services.map((service) => {
    const { slug } = service;
    durationValues[slug] = '';
    return true;
  });
  return durationValues;
};

const getSpecializationPauses = (specializations) => {
  const pauses = {};
  specializations.map((specialization) => {
    const speccializationPauselist = {};
    specialization.doctorServices.map((service) => {
      const { pause, id } = service;
      speccializationPauselist[id] = pause ? `${pause}` : '-';
      return true;
    });
    pauses[specialization.doctorSpecializationId] = speccializationPauselist;
    return true;
  });
  return pauses;
};

const getAddSpecializationPauses = (services) => {
  const pausesValues = {};
  services.map((service) => {
    const { slug } = service;
    pausesValues[slug] = '';
    return true;
  });
  return pausesValues;
};

const getServiceCheckboxValue = (services) => {
  const checkedValues = {};
  services.map((service) => {
    const { slug } = service;
    checkedValues[slug] = false;
    return true;
  });
  return checkedValues;
};

const getSpecializationActiveService = (specializations) => {
  const activeService = {};
  specializations.map((specialization) => {
    const activeServicelist = {};
    specialization.doctorServices.map((service) => {
      const { id, is_active: isActive } = service;
      activeServicelist[id] = isActive;
      return true;
    });
    activeService[specialization.doctorSpecializationId] = activeServicelist;
    return true;
  });
  return activeService;
};

const sendToCloud = async (files, media) => {
  if (!Array.isArray(files) || !files.length) return;
  const getUploadPolicy = (fileName) => media.find((row) => row.original_file_name === fileName);
  await Promise.all(
    files.map(async (file) => {
      const uploadPolicy = getUploadPolicy(file.name);
      await GCStorageService.uploadToGCWithSignedUrl(
        uploadPolicy.gcloud_upload_policy_v4.url,
        uploadPolicy.gcloud_upload_policy_v4.fields,
        file,
      );
      return true;
    }),
  );
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

const solveGender = (gender) => {
  if (!gender) {
    return '-';
  }
  switch (gender) {
    case 'male':
      return 'male';
    case 'female':
      return 'female';
    default:
      return '-';
  }
};

const getCurrentLocale = () => {
  return StorageService.getData('APP_LANGUAGE', 'ro');
};

const getBrowserVisibilityProp = (
  document: Document & { msHidden?: boolean; webkitHidden?: boolean },
) => {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
  }
  if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange';
  }
  if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
  return '';
};

const getBrowserDocumentHiddenProp = (
  document: Document & { msHidden?: boolean; webkitHidden?: boolean },
) => {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
  }
  if (typeof document?.msHidden !== 'undefined') {
    return 'msHidden';
  }
  if (typeof document?.webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
  return '';
};

const getIsDocumentHidden = () => {
  return !document[getBrowserDocumentHiddenProp(document)];
};

const isMobileDevice = () => {
  const sUsrAg = navigator.userAgent;

  return (
    sUsrAg.indexOf('iPhone') > -1 ||
    sUsrAg.indexOf('iPad') > -1 ||
    sUsrAg.indexOf('iPod ') > -1 ||
    sUsrAg.indexOf('Android') > -1
  );
};

const isiPhone = () => {
  const sUsrAg = navigator.userAgent;

  return sUsrAg.indexOf('iPhone') > -1;
};

const isSupportedBrowser = () => {
  let sBrowser = 'unknown';
  const sUsrAg = navigator.userAgent;

  // The order matters here, and this may report false positives for unlisted browsers.

  if (sUsrAg.indexOf('Firefox') > -1 || sUsrAg.indexOf('FxiOS') > -1) {
    sBrowser = 'Mozilla Firefox';
  } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
    sBrowser = 'Samsung Internet';
  } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
    sBrowser = 'Opera';
  } else if (sUsrAg.indexOf('Trident') > -1) {
    sBrowser = 'Microsoft Internet Explorer';
  } else if (sUsrAg.indexOf('Edge') > -1) {
    sBrowser = 'Microsoft Edge (Legacy)';
  } else if (sUsrAg.indexOf('Edg') > -1 || sUsrAg.indexOf('EdgiOS') > -1) {
    sBrowser = 'Microsoft Edge (Chromium)';
  } else if (sUsrAg.indexOf('Chrome') > -1 || sUsrAg.indexOf('CriOS') > -1) {
    sBrowser = 'Google Chrome or Chromium';
  } else if (sUsrAg.indexOf('Safari') > -1) {
    sBrowser = 'Apple Safari';
  }

  return sBrowser === 'Mozilla Firefox' || sBrowser === 'Google Chrome or Chromium';
};

const oneHourMinutes = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51,
  52, 53, 54, 55, 56, 57, 58, 59, 60,
];

const getNumberAfterPossiblyEliminatingZero = (defaultNumber = '', defaultCode) => {
  if (defaultNumber.length > 0 && defaultNumber.startsWith('0')) {
    const number = defaultNumber.slice(1);
    return { number, formattedNumber: defaultCode ? `+${defaultCode}${number}` : number };
  }
  return {
    number: defaultNumber,
    formattedNumber: defaultCode ? `+${defaultCode}${defaultNumber}` : defaultNumber,
  };
};

const withoutMetadata = (_parsedPhone) => {
  if (!_parsedPhone) return _parsedPhone;
  const { metadata: _metadata, ...parsedPhone } = _parsedPhone;
  return parsedPhone;
};

const getTypeAddExceptionOrAvailability = (date, value, format, type) => {
  // TODO
  // value = 15 => means that we have a start time and we need to use date.startTime
  // value = 45 => means that we have an end time and we need to use date.endTime
  // Change condition based on value === 15
  const elseTypeValue = date?.id
    ? dayjs(
        `${dayjs(date?.date).format('YYYY-MM-DD')} ${value === 15 ? date.startTime : date.endTime}`,
      ).format(format)
    : dayjs(`${dayjs().format('YYYY-MM-DD')}`).format(format);

  return type === 'add' ? dayjs().add(value, 'minute').format(format) : elseTypeValue;
};

const calculateAgeYMD = (date) => {
  const now = dayjs();
  const then = dayjs(date);

  const ageYears = now.diff(then, 'year');
  const ageMonths = now.diff(then, 'month');
  const ageDays = now.diff(then, 'day');

  return { years: ageYears, months: ageMonths, days: ageDays };
};

export default {
  calculateAge,
  makeSectionListFormat,
  capitalizeFirstLetter,
  convertAmount,
  getObjectKeyByNestedValue,
  makeSelectOptions,
  makeSelectOptions2,
  getMedicalFilesByKey,
  parseToInt,
  rejoinableAppointment,
  convertIsoDate,
  maybeLongText,
  shortenText,
  getCurrencyIdByCode,
  sortObjectByKey,
  sortObjectByDateKey,
  durationBetweenDates,
  appointmentTypeMapper,
  solveImageLogoByType,
  formatExpirationCard,
  addDays,
  makeRandomIdentifier,
  stringFormat,
  arrayToObject,
  roundToTwo,
  toFixedTwo,
  randomColor,
  getDaysBetween,
  getDaysHoursMinutesBetweenDates,
  getCurrentWeekDates,
  zeroPad,
  getSpecializationPrices,
  sendToCloud,
  dateFormat,
  getSpecializationDuration,
  getSpecializationPauses,
  getSpecializationActiveService,
  formatBytes,
  solveGender,
  getCurrentLocale,
  getIsDocumentHidden,
  getBrowserVisibilityProp,
  isMobileDevice,
  isiPhone,
  isSupportedBrowser,
  oneHourMinutes,
  getNumberAfterPossiblyEliminatingZero,
  withoutMetadata,
  getTypeAddExceptionOrAvailability,
  timeUntil,
  calculateAgeYMD,
  getServiceCheckboxValue,
  getAddSpecializationPrices,
  getAddSpecializationDuration,
  getAddSpecializationPauses,
};
