import 'patient/styles/account-assistance.css';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { supportFormRequest } from 'shared/redux/actions/SupportActions';
import { useTranslation } from 'react-i18next';
import { isDoctor } from '../../../shared/services/EnvService';
import {
  getPatientProfileState,
  getUserProfileState,
} from '../../../shared/redux/src/StatesGetter';

const PatientAccountContactBlock = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const profileData = useSelector(
    isDoctor(true) ? getUserProfileState : getPatientProfileState,
    shallowEqual,
  );

  const [showModalContact, setModalContactShow] = useState(false);
  const [firstName, setFirstName] = useState(profileData?.firstName);
  const [lastName, setLastName] = useState(profileData?.lastName);
  const [email, setEmail] = useState(profileData?.email);
  const [phoneNumber, setPhoneNumber] = useState(profileData?.phoneNumber);
  const [contactSubject, setContactSubject] = useState('');
  const [contactContent, setContactContent] = useState('');
  const modalContactClose = () => setModalContactShow(false);
  const modalContactShow = () => setModalContactShow(true);
  const handleContactSubjectChange = (e) => setContactSubject(e.target.value);
  const handleContactContentChange = (e) => setContactContent(e.target.value);
  const sendContactRequest = () => {
    dispatch(
      supportFormRequest({
        firstName,
        lastName,
        email,
        phoneNumber,
        subject: contactSubject,
        content: contactContent,
      }),
    );
    setContactSubject('');
    setContactContent('');

    modalContactClose();
  };

  return (
    <div className="account-assistance">
      <div className="title-section">
        <h2>{t('contactPage.contactInformation')}</h2>
      </div>
      <div className="contact">
        <div className="border-box">
          <div className="subtitle">{t('contactPage.applicationProgram')}</div>
          <div className="col-12 pt-4 pt-md-0">
            <div className="contact-info-text">{`${t('day_1')} - ${t('day_7')}: 8:00 - 24:00`}</div>
            <div className="contact-info-text">contact@ringdoc.ro</div>
            <div className="subtitle">{t('address')}</div>
            <div className="contact-info-text">
              Alo Doc App SRL
              <br />
              Calea Floreasca 169A, Cladirea A, Unitatea A.3.3, Et. 3, Sector. 1, Bucuresti 020285,
              Romania
            </div>
          </div>
        </div>
        <div className="information mt-4">
          <button type="button" className="white-button" onClick={modalContactShow}>
            {t('support.supportForm')}
            <CustomIcon
              className="custom-icon"
              color={Colors.darkGrey}
              size="24"
              icon="Right_pointer"
            />
          </button>
        </div>
      </div>

      <Modal
        show={showModalContact}
        onHide={modalContactClose}
        animation
        centered
        className="modal-contact"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{t('contact')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="info-message">
            <CustomIcon
              className="custom-icon"
              color={Colors.mediumGrey}
              size="24"
              icon="Warning_2"
            />
            {t('contactText')}
          </div>
          <form>
            <div className="form-input">
              <input
                autoComplete="off"
                id="firstName"
                name="firstName"
                type="text"
                className="form-control"
                placeholder=" "
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
              <label htmlFor="firstName">{t('firstName')}</label>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="lastName"
                name="lastName"
                type="text"
                className="form-control"
                placeholder=" "
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
              <label htmlFor="lastName">{t('lastName')}</label>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="email"
                name="email"
                type="text"
                className="form-control"
                placeholder=" "
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <label htmlFor="email">{t('email')}</label>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                className="form-control"
                placeholder=" "
                value={phoneNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
              />
              <label htmlFor="phoneNumber">{t('phoneNumber')}</label>
            </div>
            <div className="form-input">
              <input
                autoComplete="off"
                id="subject"
                name="subject"
                type="text"
                className="form-control"
                placeholder=" "
                value={contactSubject}
                onChange={handleContactSubjectChange}
              />
              <label htmlFor="subject">{t('support.subject')}</label>
            </div>
            <div className="form-textarea">
              <textarea
                id="message"
                name="message"
                className="form-control"
                placeholder=" "
                rows={5}
                value={contactContent}
                onChange={handleContactContentChange}
              />
              <label htmlFor="message">{t('support.message')}</label>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="orange-button" onClick={sendContactRequest}>
            {t('send')}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PatientAccountContactBlock;
